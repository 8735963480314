import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { styles } from "../css/shared-css"



export function CronStringControl(props: any) {

  const Cron = props.Cron;
  const CronParts = Cron.split(' ');
  const setCronStringValue = props.setCronString;
  const isCronValid = props.isCronValid;

  const [cronMins, setCronMins] = useState(CronParts.length > 1 ? CronParts[1] : '');
  const [cronHours, setCronHours] = useState(CronParts.length > 2 ? CronParts[2] : '');
  const [cronFrequency, setCronFrequency] = useState('Daily');
  const [cronDayWeek, setCronDayWeek] = useState(CronParts.length > 5 ? CronParts[5] : '');
  const [cronDayMonth, setCronDayMonth] = useState(CronParts.length > 3 ? CronParts[3] : '');

  useEffect(() => {
    setCronStringValue(CreateCronString());
    isCronStringValid();
  }, [cronMins, cronHours, cronFrequency, cronDayWeek, cronDayMonth]);

  useEffect(() => {
    let parts = Cron.split(' ');
    if (parts.length >= 5) {
      setCronMins(parts[1]);
      setCronHours(parts[2]);
      setCronDayWeek(parts[5] === "?" || parts[5] === "*" ? '' : parts[5]);
      setCronDayMonth(parts[3] === "?" || parts[3] === "*" ? '' : parts[3]);

      if (parts[5] === '*') {
        setCronFrequency('Daily');
      }
      else if (parts[5] === '?') {
        setCronFrequency('Monthly');
      }
      else {
        setCronFrequency('Weekly');
      }
    }

  }, [Cron]);

  const handleCronMins = (_prop: any) => (event: any) => {
    setCronMins(event.target.value);
  }

  const handleCronHours = (_prop: any) => (event: any) => {
    setCronHours(event.target.value);
  }

  const handleCronFrequency = (_prop: any) => (event: any) => {
    setCronFrequency(event.target.value);
  }

  const handleCronDayWeek = (_prop: any) => (event: any) => {
    let tempCronWeek = event.target.value;
    if (typeof tempCronWeek === 'number' && !isNaN(tempCronWeek)) {
      if (Number.isInteger(tempCronWeek)) {
        setCronDayWeek(parseInt(event.target.value));
      }
    }
    else {
      setCronDayWeek(event.target.value);
    }
  }

  const handleCronDayMonth = (_prop: any) => (event: any) => {
    setCronDayMonth(event.target.value);
  }

  function CreateCronString(): string {
    let cronStringValue = '';

    switch (cronFrequency) {
      case "Daily":
        cronStringValue = '0 ' + cronMins + ' ' + cronHours + ' ? * *';
        break;
      case "Weekly":
        cronStringValue = '0 ' + cronMins + ' ' + cronHours + ' ? * ' + cronDayWeek;
        break;
      case "Monthly":
        cronStringValue = '0 ' + cronMins + ' ' + cronHours + ' ' + cronDayMonth + ' * ?';
        break;
    }
    cronStringValue = cronStringValue.replace('NaN', '');

    return cronStringValue;
  }

  function isCronStringValid(): void {
    let isValid = true;
    let cronStringValue = CreateCronString();

    let splitCronLength = cronStringValue.split(' ').length;
    let cronStringSplitValue = cronStringValue.split(' ');

    if (splitCronLength !== 5 && splitCronLength !== 6) {

      isValid = false
    }
    else if (
      !isMonthAndWeekValueRangeValid(cronStringSplitValue[5], 'Week') ||
      !isMonthAndWeekValueRangeValid(cronStringSplitValue[3], 'Month')
    ) {
      isValid = false;
    }
    else {

      for (const element of cronStringSplitValue) {

        // check if value is a number
        if (!isNaN(+element)) {
          if (!Number.isInteger(+element)) {
            isValid = false;
            break;
          }
        }
      }
    }
    isCronValid(isValid);
  }

  function isMonthAndWeekValueRangeValid(vals: string, valueType: string): boolean {
    let isValid = true;
    let maxAllowedValue = valueType === 'Week' ? 7 : 31;

    let splittedValue = vals.split(',');

    for (const element of splittedValue) {

      // only valid non-numeric values are *, ?
      if (isNaN(+element)) {
        if (element !== '*' && element !== '?') {
          isValid = false;
          break;
        }
      }

      // if its numeric check value is a whole number
      else {
        if (!Number.isInteger(+element)) {
          isValid = false;
          break;
        }

        if (Number.parseInt(element) < 1 || Number.parseInt(element) > maxAllowedValue) {
          isValid = false;
          break;
        }

      }
    }

    return isValid;
  }

  return (<Box
    style={styles.configOptionRowElement}
  >
    <TextField id="jc-cron-minutes" label="Run minutes" variant="outlined" value={cronMins}
      onChange={handleCronMins("minute")}
    />
    <TextField id="jc-cron-hours" label="Run hours" variant="outlined" value={cronHours}
      onChange={handleCronHours("hour")}
    />
    <TextField
      id="jc-cron-frequency"
      select
      label="Frequency"
      value={cronFrequency}
      onChange={handleCronFrequency("frequency")}
      SelectProps={{
        native: true,
      }}
      helperText="Please select Frequency of the cron Job"
      variant="outlined"
    >
      <option key="1" value="Daily">Daily</option>
      <option key="2" value="Weekly">Weekly</option>
      <option key="3" value="Monthly">Monthly</option>
    </TextField>

    {
      cronFrequency === "Weekly" &&
      <TextField id="jc-cron-day-of-week" label="Day of week" variant="outlined" value={cronDayWeek}
        onChange={handleCronDayWeek("dayOfWeek")}
      />
    }

    {
      cronFrequency === "Monthly" &&
      <TextField id="jc-cron-day-of-month" label="Day of month" variant="outlined" value={cronDayMonth}
        onChange={handleCronDayMonth("dayOfMonth")}
      />
    }
  </Box>)
}

