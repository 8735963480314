import { createBrowserHistory } from "history"

export function getEnvVarByName(name) {
  // When runnign via docker, we inject the env vars into a config.js file which is loaded and attaches the vars
  // to the window object. This let's them be assigned at runtime rather than at build time
  // For local dev, we still assign at build time by using the .env file
  if (window[name]) return window[name]
  if (process.env[name]) return process.env[name]
  console.warn(`Failed to find env var with name '${name}'`)
  return null
}

export const history = createBrowserHistory()
