import React, { FC, useEffect, useState } from "react"
import {
  Box,
  TextField,
  Button,
  Grid,
  makeStyles,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody
} from "@material-ui/core"
import "react-datepicker/dist/react-datepicker.css"
import { styles } from "../../css/shared-css"
import { ADPSubscriptionMappingAPIService } from "services/ADPSubscriptionMappingService"
import { Add, Delete, Save } from "@material-ui/icons"
import { IADPSubscriptionMapping } from "models/IADPSubscriptionMapping"

require("react-datepicker/dist/react-datepicker.css")

const useStyles = makeStyles({
  saveButton: {
    marginLeft: "0.5em"
  },
  existingIdRow: {
    backgroundColor: "auto"
  },
  newIdRow: {
    backgroundColor: "#ffe345"
  }
})

export const ADPSubscriptionMappings = () => {
  const [mappings, setMappings] = useState<IADPSubscriptionMapping[]>([])
  const [modifiedRow, setModifiedRow] = useState<boolean[]>([])
  const [initialLoaded, setInitialLoaded] = useState(false)

  const fetchADPMappings = async () => {
    const adpSubscriptionMappings = await ADPSubscriptionMappingAPIService.GetADPMappings()
    let modifiedRowsInit: boolean[] = []
    adpSubscriptionMappings.forEach(() => {
      modifiedRowsInit.push(false)
    })

    setModifiedRow(modifiedRowsInit)
    setMappings(adpSubscriptionMappings)
    setInitialLoaded(true)
  }

  useEffect(() => {
    if (!initialLoaded) {
      fetchADPMappings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addNewMapping = () => {
    const newMapping: IADPSubscriptionMapping = {
      id: undefined,
      name: "",
      description: "",
      status: "INACTIVE",
      subscriberId: "",
      created: new Date(),
      modified: new Date()
    }

    setMappings([...mappings, newMapping])
    setModifiedRow([...modifiedRow, true])
  }

  const updateMapping = (key: any, mapping: IADPSubscriptionMapping) => {
    let updatedMappings = [...mappings]
    updatedMappings[key] = mapping
    setMappings(updatedMappings)
    let modifiedRows = [...modifiedRow]
    modifiedRows[key] = true
    setModifiedRow(modifiedRows)
  }

  const deleteMapping = async (key: any, deletedMapping: IADPSubscriptionMapping) => {
    if (deletedMapping.id) {
      const result = await ADPSubscriptionMappingAPIService.DeleteADPMapping(deletedMapping.id)
      if (!result) {
        return
      }
    }
    const updatedMappings = mappings.filter((m) => m !== deletedMapping)
    setMappings(updatedMappings)

    let modifiedRows = [...modifiedRow]
    modifiedRows.splice(key, 1)
    setModifiedRow(modifiedRows)
  }

  const saveMapping = async (key: any, mapping: IADPSubscriptionMapping) => {
    if (mapping.id) {
      ADPSubscriptionMappingAPIService.UpdateADPMapping(mapping)
    } else {
      const result = await ADPSubscriptionMappingAPIService.PostADPMapping(mapping)
      let updatedMappings = [...mappings]
      updatedMappings[key].id = result.id
      setMappings(updatedMappings)
    }
    let modifiedRows = [...modifiedRow]
    modifiedRows[key] = false
    setModifiedRow(modifiedRows)
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Mapping ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Subscriber ID</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Last Modified</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {initialLoaded &&
              mappings.map((mapping, key) => (
                <ADPMappingRow
                  key={key}
                  mapping={mapping}
                  index={key}
                  modified={modifiedRow[key]}
                  onChange={updateMapping}
                  onDelete={deleteMapping}
                  onSave={saveMapping}
                />
              ))}
            <Grid item xs={12}>
              <Box style={styles.box}>
                <Button variant="contained" color="primary" onClick={addNewMapping} id="addMapping">
                  <Add />
                  Add Mapping
                </Button>
              </Box>
            </Grid>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

interface ADPMappingRowProps {
  mapping: IADPSubscriptionMapping
  index: number
  modified: boolean
  onChange: (key: any, mapping: IADPSubscriptionMapping) => void
  onDelete: (key: any, deletedMapping: IADPSubscriptionMapping) => void
  onSave: (key: any, mapping: IADPSubscriptionMapping) => void
}

const ADPMappingRow: FC<ADPMappingRowProps> = ({ mapping, index, modified, onChange, onDelete, onSave }) => {
  const classes = useStyles()
  return (
    <TableRow>
      <React.Fragment key={index}>
        <TableCell id="parent" className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="mappingId">
            <td style={{ textAlign: "center" }}>{mapping.id}</td>
          </Box>
        </TableCell>
        <TableCell className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="mappingName">
            <TextField
              value={mapping.name}
              onChange={(e) => {
                let updatedMapping = { ...mapping, name: e.target.value }
                onChange(index, updatedMapping)
              }}
              variant="filled"
            ></TextField>
          </Box>
        </TableCell>
        <TableCell className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="mappingDescription">
            <TextField
              multiline={true}
              value={mapping.description}
              onChange={(e) => {
                let updatedMapping = { ...mapping, description: e.target.value }
                onChange(index, updatedMapping)
              }}
              variant="filled"
            ></TextField>
          </Box>
        </TableCell>
        <TableCell className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="mappingSubscriberId">
            <td style={{ textAlign: "center" }}>{mapping.subscriberId}</td>
          </Box>
        </TableCell>
        <TableCell className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="mappingStatus">
            <td style={{ textAlign: "center" }}>{mapping.status}</td>
          </Box>
        </TableCell>
        <TableCell className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="mappingModifiedDate">
            <td style={{ textAlign: "center" }}>{new Date(mapping.modified).toLocaleString()}</td>
          </Box>
        </TableCell>
        <TableCell>
          <TableCell style={{ borderBottom: "none" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={(_e) => onSave(index, mapping)}
              className={classes.saveButton}
            >
              <Save />
            </Button>
          </TableCell>
          <TableCell style={{ borderBottom: "none" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={(_e) => onDelete(index, mapping)}
              className={classes.saveButton}
            >
              <Delete />
            </Button>
          </TableCell>
        </TableCell>
      </React.Fragment>
    </TableRow>
  )
}
