import { REACT_APP_API_BASE } from "services/Api/ApiBaseRouteConstants"
import { ApiWrapper } from "./AxiosWrapper"
import { PaycorPerformDepartmentMapping } from "models/PaycorPerformDepartmentMapping"

class PaycorPerformDepartmentMappingApi {
  private PAYCOR_PERFORM_DEPARTMENT_MAPPING_URI = "PaycorPerformDepartmentMapping/"

  public async GetPaycorPerformDepartmentMappingsByChainId(
    chainId: number
  ): Promise<PaycorPerformDepartmentMapping[]> {
    return ApiWrapper.get(`${REACT_APP_API_BASE}${this.PAYCOR_PERFORM_DEPARTMENT_MAPPING_URI}Chain/${chainId}`, {})
  }

  public async PostPaycorPerformDepartmentMapping(mapping: PaycorPerformDepartmentMapping): Promise<PaycorPerformDepartmentMapping> {
    return ApiWrapper.post(REACT_APP_API_BASE + this.PAYCOR_PERFORM_DEPARTMENT_MAPPING_URI, mapping, {})
  }

  public async UpdatePaycorPerformDepartmentMapping(mapping: PaycorPerformDepartmentMapping): Promise<PaycorPerformDepartmentMapping> {
    return ApiWrapper.put(`${REACT_APP_API_BASE}${this.PAYCOR_PERFORM_DEPARTMENT_MAPPING_URI}${mapping.id}`, mapping, {})
  }

  public async DeletePaycorPerformDepartmentMapping(id: string): Promise<any> {
    return ApiWrapper.delete(`${REACT_APP_API_BASE}${this.PAYCOR_PERFORM_DEPARTMENT_MAPPING_URI}${id}`, {}).then((response) => {
      if (response.status === 200) {
        return id
      }
      return undefined
    })
  }
}

export const PaycorPerformDepartmentMappingApiService: PaycorPerformDepartmentMappingApi = new PaycorPerformDepartmentMappingApi()