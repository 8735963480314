import { IJobExecutionLog } from "models/IJobExecutionLog"
import { REACT_APP_API_BASE } from "services/Api/ApiBaseRouteConstants"
import { JOBEXECUTIONLOGAPIURI } from "services/Api/JobConfigServiceConstants"
import { ApiWrapper } from "./AxiosWrapper"
import { JobExecutionLog } from "models/JobExecutionLog"

class JobExecutionLogApi {
  public GetJobExecutionLogs(
    pageNumber: number,
    pageSize: number,
    configId: string
  ): Promise<{ list: IJobExecutionLog[]; pages: number }> {
    return ApiWrapper.get(REACT_APP_API_BASE + JOBEXECUTIONLOGAPIURI + "list", {
      pageNumber: pageNumber,
      pageSize: pageSize,
      configId: configId
    })
  }

  public async GetExecutionLogDetails(id: string): Promise<IJobExecutionLog> {
    let executionLog: JobExecutionLog = new JobExecutionLog(
      await ApiWrapper.get(`${REACT_APP_API_BASE}${JOBEXECUTIONLOGAPIURI}details`, { id: id })
    )

    return executionLog
  }
}

export const JobExecutionLogService: JobExecutionLogApi = new JobExecutionLogApi()
