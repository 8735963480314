import { ApiWrapper } from "./AxiosWrapper"
import { SCHEDULE_AUTH0_CLIENT_ID } from "../components/Auth/PaycorAuthConstants"
import { AUTH_DOMAIN } from "./Api/Auth0Constants"

class Auth0Api {
  public Login(requestUsername: string, requestPassword: string): Promise<any> {
    return ApiWrapper.post(
      "https://" + AUTH_DOMAIN + "/oauth/token", 
      {
        grant_type: "password",
        audience: "https://api.onshift.com",
        scope: "read:sample",
        client_id: SCHEDULE_AUTH0_CLIENT_ID,
        username: requestUsername,
        password: requestPassword
      },
      {}
    )
  }
}

    
export const Auth0ApiService: Auth0Api = new Auth0Api()