import { Box, FormControl, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { styles } from "css/shared-css"
import { ICompany } from "models/MCP/ICompany"
import React, { FC, useEffect, useState } from "react"
import { McpApiService } from "services/McpApiService"

export interface CompanySelectorProps {
  company: ICompany | null
  setCompany: (company: ICompany | null) => void
}

export const CompanySelector: FC<CompanySelectorProps> = ({ company, setCompany }) => {
  const [userCompanies, setUserCompanies] = useState<ICompany[]>([])
  const [isLoadingCompany, setIsLoadingCompany] = useState(false)

  const fetchCompanies = async () => {
    setIsLoadingCompany(true)
    var userAvailableCompanies = await McpApiService.GetUserCompanies().finally(() => {
      setIsLoadingCompany(false)
    })
    setUserCompanies(userAvailableCompanies ?? [])
    if (userAvailableCompanies && userAvailableCompanies.length === 1) {
      setCompany(userAvailableCompanies[0])
    }
  }

  useEffect(() => {
    fetchCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box style={styles.box}>
      <FormControl style={styles.fullLength}>
        <Autocomplete
          id="company"
          value={company}
          loading={isLoadingCompany}
          options={userCompanies}
          getOptionLabel={(option) => option.name}
          disabled={userCompanies.length <= 1}
          onChange={(e, value) => setCompany(value)}
          renderInput={(params) => (
            <TextField {...params} style={styles.fullLength} label="Company" variant="outlined" />
          )}
        />
      </FormControl>
    </Box>
  )
}
