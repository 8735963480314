import React from "react";
import { Box, TextField, MenuItem, Select, InputLabel, FormControl, Paper, Button, FormHelperText, FormLabel, OutlinedInput } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { JobConfigApiService } from "services/JobConfigApiService";
import { DestinationCheckbox } from "../components/job-config-destination-checkbox";
import * as JobConfigDestinations from "models/constants/JobConfigDestinationConstants"
import { IChain } from "models/IChain";
import { IScheduleMessage } from "models/IScheduleMessage"
import { getChains } from "services/ScheduleDataApiService";
import "react-datepicker/dist/react-datepicker.css";
import { KeyboardDatePicker } from "@material-ui/pickers";
import WithErrorHandling from "../components/error-banner";
import { IJobConfigSchedule } from "../models/IJobConfigSchedule";
import { RedirectDialog } from "./RedirectDialog"
import { styles } from "../css/shared-css";
import { McpApiService } from "services/McpApiService";
import { ICompany } from "models/MCP/ICompany";
import { PasswordField } from "components/PasswordField";
import { IsVendorRequiringCredentials } from "../models/JobConfig"
import { CronStringControl } from "components/CronString";
import { IJobConfig } from '../models/IJobConfig';

require('react-datepicker/dist/react-datepicker.css')

export type JobConfigNewProps = {};
export type JobConfigNewState = {
    description: string,
    jobConfigId: string,
    savedAt: Date,
    isLoadingChain: boolean,
    isLoadingCompany: boolean,
    chains: IChain[],
    companies: ICompany[],
    integrationTypes: string[],
    vendorTypes: string[],
    destinations: number[],
    isLegacyActive: boolean,
    isNextGenActive: boolean,
    isTimeActive: boolean,
    isWalletActive: boolean,
    chain: IChain | null,
    company: ICompany | null,
    integrationType: string,
    vendorType: string,
    requiresCredentials: boolean,
    showPassword: boolean,
    errorIntegrationType: boolean,
    errorVendorType: boolean,
    errorChain: boolean,
    errorCompany: boolean,
    errorCron: boolean,
    startDate: Date,
    endDate: Date,
    cron: string,
    showError: boolean,
    errorMessage: string,
    data: string,
    username: string | null,
    password: string | null,
    showRedirectToEditDialog: boolean,
    redirectConfigID: string,
    hasLoadedChains: boolean,
    hasLoadedCompanies: boolean
};

export class JobConfigNew extends React.Component<JobConfigNewProps, JobConfigNewState> {
    state: JobConfigNewState

    constructor(props: any) {
        super(props)

        this.state = {
            description: '',
            jobConfigId: '',
            savedAt: new Date(),
            isLoadingChain: true,
            isLoadingCompany: true,
            chains: [],
            companies: [],
            integrationTypes: [],
            vendorTypes: [],
            destinations: [],
            isLegacyActive: false,
            isNextGenActive: false,
            isTimeActive: false,
            isWalletActive: false,
            chain: null,
            company: null,
            integrationType: '',
            vendorType: '',
            requiresCredentials: false,
            showPassword: false,
            errorIntegrationType: false,
            errorVendorType: false,
            errorChain: false,
            errorCompany: false,
            errorCron: false,
            startDate: new Date(),
            endDate: new Date(),
            cron: '',
            showError: false,
            errorMessage: '',
            data: '',
            username: '',
            password: '',
            showRedirectToEditDialog: false,
            redirectConfigID: '',
            hasLoadedChains: false,
            hasLoadedCompanies: false
        }
    }

    async _setup(): Promise<void> {
        try {
            this.setState({ integrationTypes: await JobConfigApiService.GetIntegrationTypes() });
            this.setState({ vendorTypes: await JobConfigApiService.GetVendorTypes() });
        } catch (ex) {
            this.setState({ showError: true, errorMessage: "Error while setting up: " + ex.message });
        }
    }

    async loadCompanies(): Promise<void> {
        if (!this.state.hasLoadedCompanies) {     
            try {
                this.setState({
                    companies: await McpApiService.GetUserCompanies().finally(() => {
                        this.setState({ isLoadingCompany: false })
                        this.setState({ hasLoadedCompanies: true })
                    })
                })
            } catch (ex) {
                this.setState({ showError: true, errorMessage: "Error while setting up: " + ex.message });
            }
        }
    }

    async loadChain(): Promise<void> {
        this.toggleDestinations(JobConfigDestinations.JOB_CONFIG_DESTINATION_LEGACY_SCHEDULE);
        if (!this.state.hasLoadedChains) {
            try {
                this.setState({
                    chains: await getChains().finally(() => {
                        this.setState({ isLoadingChain: false })
                        this.setState({ hasLoadedChains: true })
                    })
                })
            } catch (ex) {
                this.setState({ showError: true, errorMessage: "Error while setting up: " + ex.message });
            }
        }
    }
    async loadCompaniesNG(): Promise<void> {
        this.toggleDestinations(JobConfigDestinations.JOB_CONFIG_DESTINATION_NEXT_GEN_SCHEDULE);
        this.loadCompanies();
    }

    async loadCompaniesWallet(): Promise<void> {
        this.toggleDestinations(JobConfigDestinations.JOB_CONFIG_DESTINATION_WALLET);
        this.loadCompanies();
    }

    onCompanyChange = (_event: any, selectedCompany: ICompany | null) => {
        if (selectedCompany != null) {
            this.setState(
                {
                    company: selectedCompany,
                    errorCompany: false
                }
            )
        }
    }

    componentDidMount(): void {
        this._setup();
    }

    componentWillUnmount(): void {
        // No action needed at the moment
    }

    setOpen(value: boolean): void {
        this.setState({ showError: value });
    }

    setRedirectDialogOpen(value: boolean): void {
        this.setState({ showRedirectToEditDialog: value });
    }

    setPassword(value: any): void {
        this.setState({
            password: value
        })
    }

    handleClickShowPassword(): void {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    isCronStringValid(isValid: boolean): void {
        this.setState(_prevState => ({
            errorCron: !isValid
          }));
    }

    setCron(value: any): void {
        this.setState(_prevState => ({
            cron: value
        }));
    }

    toggleDestinations(checkedDestination: number): void {

        let isNewDestination = true
        let currentDestinations = this.state.destinations
        let newDestinations: number[] = []

        currentDestinations.forEach(function (currentDestination) {
            if (currentDestination === checkedDestination) {
                isNewDestination = false
            } else {
                newDestinations.push(currentDestination)
            }
        })

        if (isNewDestination) {
            newDestinations.push(checkedDestination)
        }

        this.setState({ destinations: newDestinations })

        switch (checkedDestination) {
            case JobConfigDestinations.JOB_CONFIG_DESTINATION_LEGACY_SCHEDULE: {
                this.setState({ isLegacyActive: !this.state.isLegacyActive })
                break;
            }
            case JobConfigDestinations.JOB_CONFIG_DESTINATION_NEXT_GEN_SCHEDULE: {
                this.setState({ isNextGenActive: !this.state.isNextGenActive })
                break;
            }
            case JobConfigDestinations.JOB_CONFIG_DESTINATION_WALLET: {
                this.setState({isWalletActive:!this.state.isWalletActive})
                break;
            }
            case JobConfigDestinations.JOB_CONFIG_DESTINATION_ON_TIME: {
                this.setState({ isTimeActive: !this.state.isTimeActive })
                break;
            }
        }
    }
    async IsValidConfig(): Promise<boolean> {
        let isValid = true;
        // if cron string was not valid..
        if (this.state.errorCron) {
            this.setState({ 
                showError: true, errorMessage: "Invalid cron string: must fullfill all of below criteria:\n" +
                    "Must be a valid Whole number (decimal number not allowed).\n" +
                    "Must be between 1 and 7 for week and 1 and 31 for month.\n" 
        });
            isValid = false;
        }
        if (this.state.destinations.length === 0) {
            this.setState({ showError: true, errorMessage: "Please select a product destination" })
            isValid = false
        }

        this.state.destinations.forEach((currentDestination) => {
            if (currentDestination === JobConfigDestinations.JOB_CONFIG_DESTINATION_LEGACY_SCHEDULE) {
                if (this.state.chain == null) {
                    this.setState({
                        errorChain: true,
                        showError: true,
                        errorMessage: "Please select a chain for Legacy Schedule"
                    })
                    isValid = false
                }
            }
            else if (currentDestination === JobConfigDestinations.JOB_CONFIG_DESTINATION_NEXT_GEN_SCHEDULE) {
                if (this.state.company == null) {
                    this.setState({
                        errorCompany: true,
                        showError: true,
                        errorMessage: "Please select a company for Next Gen Schedule"
                    })
                    isValid = false
                }
            }
            else if (currentDestination === JobConfigDestinations.JOB_CONFIG_DESTINATION_WALLET) {
                if (this.state.company == null) {
                    this.setState({ errorCompany: true,
                        showError: true, 
                        errorMessage: "Please select a company for Wallet" })
                    isValid = false
                }
            }
        })

        if (this.state.requiresCredentials) {
            if (this.state.username?.length === 0 ||
                this.state.password?.length === 0) {
                this.setState({
                    showError: true,
                    errorMessage: "Username and password are required for a config of this type."
                })
                isValid = false
            }
        }

        return isValid
    }

    async submitJobData(): Promise<IJobConfig | undefined> {
        let chainId = -1;
        let jobConfig;
        let companyId = null
        if (this.state.chain != null) {
            chainId = this.state.chain.chainId
        }
        if (this.state.company != null) {
            companyId = this.state.company.id.toString()
        }
        try {
            jobConfig = await JobConfigApiService.PostJobConfig({
                description: this.state.description,
                savedAt: new Date(),
                userId: null,
                chainId: chainId.toString(),
                companyId: companyId,
                intType: this.state.integrationType,
                vendorType: this.state.vendorType,
                destinations: this.state.destinations,
                data: this.state.data,
                username: this.state.username,
                password: this.state.password,
                message: null,
            });
        }
        catch (ex) {
            this.setState({ showError: true, errorMessage: "Error while inserting job config: " + ex.response.data.errors[0].ErrorObject });
        }

        return jobConfig;
    }

    async submit(_event: any): Promise<void> {
        try {
            if (await this.IsValidConfig()) {
                // State doesn't update until after this function exits
                let stateUpdate = {
                    errorIntegrationType: this.state.integrationType === '',
                    errorVendorType: this.state.vendorType === ''
                };
                this.setState(stateUpdate);
                if (stateUpdate.errorIntegrationType
                    || stateUpdate.errorVendorType) {
                    return;
                }

                let jobConfig;
                jobConfig = await this.submitJobData();

                if (jobConfig == null) {
                    // Let user know there was an error
                }
                else {
                    let scheduleMessage = {
                        integrationType: this.state.integrationType,
                        vendorType: this.state.vendorType,
                        jobConfigSchedule: {
                            id: null,
                            //stripping the time off to handle time zone edge cases
                            startDate: new Date(this.state.startDate.toDateString()),
                            endDate: new Date(this.state.endDate.toDateString()),
                            cron: this.state.cron,
                            timingType: 2
                        } as IJobConfigSchedule
                    } as IScheduleMessage;

                    try {
                        await JobConfigApiService.PostJobConfigSchedule(jobConfig.id, scheduleMessage);
                        this.setState({
                            jobConfigId: jobConfig.id
                        })
                    }
                    catch (ex) {
                        // If there was an error saving the schedule the user will be redirected to the edit screen since the job config record was already saved
                        console.error(ex.response.data.errors[0].ErrorObject);
                        this.setState({ redirectConfigID: jobConfig.id });
                        this.setState({ showRedirectToEditDialog: true });
                        this.setState({ errorMessage: ex.response.data.errors[0].ErrorObject + " You will be redirected to the edit page for this config" });
                    }
                }
            }
        }
        catch (ex) {
            this.setState({ showError: true, errorMessage: "Error while inserting: " + ex });
        }
    }

    render(): React.ReactNode {
        const { isLegacyActive, isNextGenActive, isWalletActive } = this.state

        if (this.state.jobConfigId !== '') {
            return <Redirect to={`/edit/${this.state.jobConfigId}`} />
        }

        return (
            <Paper>
                <WithErrorHandling
                    open={this.state.showError}
                    setOpen={this.setOpen.bind(this)}
                    errorMessage={this.state.errorMessage} />
                <RedirectDialog
                    title="There was an error saving the schedule:"
                    message={this.state.errorMessage}
                    open={this.state.showRedirectToEditDialog}
                    setOpen={this.setRedirectDialogOpen.bind(this)}
                    redirectLink={`/edit/${this.state.redirectConfigID}`} />
                <Box style={styles.box}>
                    <FormControl style={styles.formControl}>
                        <FormLabel style={styles.formLabel}>Product</FormLabel>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <DestinationCheckbox checkboxText="Legacy Schedule" 
                                            toggleDestination={this.loadChain.bind(this)} 
                                            destination={JobConfigDestinations.JOB_CONFIG_DESTINATION_LEGACY_SCHEDULE} />
                                    </td>
                                    <td>
                                        <DestinationCheckbox checkboxText="Next Gen Schedule" 
                                            toggleDestination={this.loadCompaniesNG.bind(this)} 
                                            destination={JobConfigDestinations.JOB_CONFIG_DESTINATION_NEXT_GEN_SCHEDULE} />
                                    </td>
                                    <td>
                                        <DestinationCheckbox checkboxText="Time FTP" 
                                            toggleDestination={this.toggleDestinations.bind(this)} 
                                            destination={JobConfigDestinations.JOB_CONFIG_DESTINATION_ON_TIME} />
                                    </td>
                                    <td>
                                        <DestinationCheckbox checkboxText="Wallet FTP" 
                                            toggleDestination={this.loadCompaniesWallet.bind(this)} 
                                            destination={JobConfigDestinations.JOB_CONFIG_DESTINATION_WALLET} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </FormControl>
                </Box>
                {isLegacyActive &&
                    <Box style={styles.box}>
                        <FormControl style={styles.fullLength} error={this.state.errorChain}>
                            <Autocomplete
                                id="chain"
                                loading={this.state.isLoadingChain}
                                options={this.state.chains}
                                getOptionLabel={option => option.chainName}
                                autoSelect={true}
                                onChange={(_event: any, value: IChain | null) => this.setState.bind(this)({
                                    chain: value,
                                    errorChain: false
                                })}
                                renderInput={params =>
                                    <TextField {...params} style={styles.fullLength} label="Chain" variant="outlined" />
                                }
                            />
                            {this.state.errorChain ? <FormHelperText>Error</FormHelperText> : null}
                        </FormControl>
                    </Box>
                }
                {(isNextGenActive || isWalletActive) && 
                    <Box style={styles.box}>
                        <FormControl style={styles.fullLength} error={this.state.errorCompany}>
                            <Autocomplete
                                id="company"
                                value={this.state.company}
                                loading={this.state.isLoadingCompany}
                                options={this.state.companies}
                                getOptionLabel={(option) => option.name}
                                autoSelect={true}
                                disabled={this.state.companies == null || this.state.companies.length <= 1}
                                onChange={this.onCompanyChange.bind(this)}
                                renderInput={(params) => (
                                    <TextField {...params} style={styles.fullLength} label="Company" variant="outlined" />
                                )}
                            />
                            {this.state.errorCompany ? <FormHelperText>Error</FormHelperText> : null}
                        </FormControl>
                    </Box>
                }
                <Box style={styles.box}>
                    <FormControl style={styles.formControl} error={this.state.errorIntegrationType}>
                        <InputLabel id="integration-type-label">Integration Type</InputLabel>
                        <Select
                            labelId="integration-type-label"
                            id="integration-type"
                            value={this.state.integrationType}
                            onChange={(e) => this.setState.bind(this)({
                                integrationType: e.target.value as string,
                                errorIntegrationType: false
                            })}
                        >
                            {this.state.integrationTypes.map((value, index) =>
                                <MenuItem key={index} value={value}>{value}</MenuItem>
                            )}
                        </Select>
                        {this.state.errorIntegrationType ? <FormHelperText>Error</FormHelperText> : null}
                    </FormControl>
                    <FormControl style={styles.formControl} error={this.state.errorVendorType}>
                        <InputLabel id="vendor-type-label">Vendor Type</InputLabel>
                        <Select
                            labelId="vendor-type-label"
                            id="vendor-type"
                            value={this.state.vendorType}
                            onChange={(e) => this.setState.bind(this)({
                                vendorType: e.target.value as string,
                                errorVendorType: false,
                                requiresCredentials: IsVendorRequiringCredentials(e.target.value as string)
                            })}
                        >
                            {this.state.vendorTypes.map((value, index) =>
                                <MenuItem key={index} value={value}>{value}</MenuItem>
                            )}
                        </Select>
                        {this.state.errorVendorType ? <FormHelperText>Error</FormHelperText> : null}
                    </FormControl>
                </Box>
                <Box style={styles.box}>
                    <TextField
                        id="jc-description"
                        label="Description"
                        multiline
                        rows={4}
                        style={styles.fullLength}
                        variant="outlined"
                        onChange={(e) => {
                            let description = e.target.value;
                            this.setState(
                                (_prevState) => ({
                                    description: description
                                })
                            );
                        }}
                        value={this.state.description}
                    />
                </Box>
                <Box style={styles.configOptionRow}>
                    <Box style={styles.configOptionRowElement}>
                        <KeyboardDatePicker
                            format="MM/dd/yyyy"
                            label="Schedule start date"
                            value={this.state.startDate}
                            onChange={e => {
                                let dateString = e?.toDateString();
                                if (dateString !== undefined) {
                                    let startDate = new Date(dateString);
                                    this.setState(_prevState => ({
                                        startDate: startDate
                                    }));
                                }
                            }}
                            animateYearScrolling
                        />
                    </Box>
                    <Box style={styles.configOptionRowElement}>
                        <KeyboardDatePicker
                            format="MM/dd/yyyy"
                            label="Schedule end date"
                            value={this.state.endDate}
                            onChange={e => {
                                let dateString = e?.toDateString();
                                if (dateString !== undefined) {
                                    let endDate = new Date(dateString);
                                    this.setState(_prevState => ({
                                        endDate: endDate
                                    }));
                                }
                            }}
                            animateYearScrolling
                        />
                    </Box>
                    <Box
                        style={styles.configOptionRowElement}
                    >
                        <CronStringControl
                            Cron={this.state.cron}
                            setCronString={this.setCron.bind(this)}
                            isCronValid={this.isCronStringValid.bind(this)}
                        />
                    </Box>
                </Box>
                {this.state.requiresCredentials &&
                    <Box style={styles.configOptionRow}>
                        <Box style={styles.configOptionRowElement}>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                                <OutlinedInput
                                    label="Username"
                                    id="user"
                                    value={this.state.username}
                                    onChange={e => {
                                        let updatedUsername = e.target.value
                                        this.setState({
                                            username: updatedUsername
                                        });
                                    }}
                                    type={'text'}
                                />
                            </FormControl>
                        </Box>
                        <Box style={styles.configOptionRowElement}>
                            <PasswordField
                                handleClickShowPassword={this.handleClickShowPassword.bind(this)}
                                setPassword={this.setPassword.bind(this)}
                                password={this.state.password}
                                showPassword={this.state.showPassword}
                            />
                        </Box>
                    </Box>
                }
                <Box style={styles.box}>
                    <textarea
                        style={styles.configText}
                        name="configText"
                        spellCheck="false"
                        onChange={(e) => {
                            let configDataText = e.target.value
                            this.setState(
                                () => ({
                                    data: configDataText
                                })
                            );
                        }}
                        value={this.state.data} />
                </Box>
                <Box style={styles.box}>
                    <Button variant="contained" color="primary" onClick={(e) => this.submit(e)}>
                        Submit
                    </Button>
                </Box>
            </Paper>
        )
    }
}
