import { APIMapInterface, IncomingJobMapInterface, MapTypeEnum } from "models/MCP/DenaliMaps"
import { Department } from "models/MCP/Department"
import { ICommunity } from "models/MCP/ICommunity"
import { ICompany } from "models/MCP/ICompany"
import { DENALI_API_BASE, MCP_API_BASE, REACT_APP_API_BASE } from "./Api/ApiBaseRouteConstants"
import {
  COMMUNITIES_BY_COMPANY_ID,
  COMPANY_URI,
  DEPARTMENTS_BY_COMMUNITY_ID,
  PUNCH_PAY_CODES
} from "./Api/McpServiceConstants"
import { ApiWrapper } from "./AxiosWrapper"
import { McpApiUtils } from "../utils/McpApiServiceUtils"

class McpApi {
  public GetUserCompanies(): Promise<ICompany[]> {
    return ApiWrapper.get(MCP_API_BASE + COMPANY_URI, {}, 0)
  }

  public GetUserCommunitiesByCompany(companyId: string): Promise<ICommunity[]> {
    return ApiWrapper.get(MCP_API_BASE + COMMUNITIES_BY_COMPANY_ID(companyId), {}, 0)
  }

  public GetDepartmentsByCommunity(id: string, type: any): Promise<Department[]> {
    if (type === MapTypeEnum.FlsaCode) {
      return McpApiUtils.flsaCodes as unknown as Promise<Department[]>
    } else if (type === MapTypeEnum.PunchPayCode) {
      return ApiWrapper.get(DENALI_API_BASE + PUNCH_PAY_CODES(), {})
    } else {
      return ApiWrapper.get(MCP_API_BASE + DEPARTMENTS_BY_COMMUNITY_ID(id), {}, 0)
    }
  }

  public async GetMaps(id: string, type: any): Promise<IncomingJobMapInterface[] | void> {
    const url = `${REACT_APP_API_BASE}${McpApiUtils.actions[type](id)}`
    const response = await ApiWrapper.get(url, {})
    const modifiedResponse: any[] = []
    if (response) {
      response.forEach((r: any) => modifiedResponse.push(McpApiUtils.TypeParser(type, r)))
    }
    return modifiedResponse
  }

  public async PostMap(mapping: APIMapInterface) {
    const url = `${REACT_APP_API_BASE}${mapping.pathname}`
    return ApiWrapper.post(url, mapping.map, {})
  }

  public async UpdateMap(mapping: APIMapInterface) {
    const url = `${REACT_APP_API_BASE}${mapping.pathname}/${mapping.id}`
    return ApiWrapper.put(url, mapping.map, {})
  }

  public async DeleteMap(mapping: APIMapInterface): Promise<any> {
    const url = `${REACT_APP_API_BASE}${mapping.pathname}/${mapping.id}`
    return ApiWrapper.delete(url, {}).then((response) => {
      if (response.status === 200) {
        return mapping.id
      }
      return undefined
    })
  }
}

export const McpApiService: McpApi = new McpApi()
