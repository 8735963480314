import AxiosApi from "services/AxiosApi"
import { AxiosError, AxiosResponse } from "axios"

class AxiosWrapper {

  public async get(uri: string, params: any, reattempts = 0): Promise<any> {
    let requestHeaders = {}

    try {
      const response = await AxiosApi.get(uri, {
        headers: requestHeaders,
        params: params
      })
      return this._get_data_from_response(response)
    } catch (ex) {
      let error = ex as AxiosError
      console.log(error)
      if (error.response) {
        if (error.response.status === 401) {
          if (reattempts < 3) {
            //This is bad practice, reason is commented in method
            reattempts++
            return await this.get(uri, params, reattempts)
          }
        } else throw error
      }
    }
  }

  public post(uri: string, data: any, requestParams: any): Promise<any> {
    let requestHeaders = {}

    return AxiosApi.post(uri, data, {
      headers: requestHeaders,
      params: requestParams
    })
      .then((response) => {
        return this._get_data_from_response(response)
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  }

  public put(uri: string, data: any, params: any): Promise<any> {
    return AxiosApi.put(uri, data, {
      headers: {},
      params: params
    })
      .then((response) => {
        return this._get_data_from_response(response)
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  }

  public delete(uri: string, params: any): Promise<any> {
    return AxiosApi.delete(uri, {
      headers: {},
      params: params
    })
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  }

  public removeAuthorizationHeader() {
    delete AxiosApi.defaults.headers.common["Authorization"]
  }

  private _get_data_from_response(response: AxiosResponse) {
    return response.data.responseData ?? response.data
  }
}

export const ApiWrapper: AxiosWrapper = new AxiosWrapper()
