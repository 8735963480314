import axios from "axios"
import { REACT_APP_API_BASE } from "services/Api/ApiBaseRouteConstants"

// configured instance of Axios
const AxiosApi = axios.create({
  baseURL: REACT_APP_API_BASE
})

AxiosApi.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default AxiosApi
