import { SCHEDULE_API_BASE } from "services/Api/ApiBaseRouteConstants"
import { CHAIN_URI, HEARTBEAT_AUTH_URI } from "services/Api/ScheduleDataServiceConstants"
import { IChain } from "../models/IChain";
import { ApiWrapper } from "./AxiosWrapper";

export async function getChains(): Promise<IChain[]> {
    let chains: IChain[] = [];

    let limit: number = 100;
    let skip: number = 0;
    let hasMore: boolean = true;
    try {
        do {
            let response = await ApiWrapper.get(`${SCHEDULE_API_BASE}${CHAIN_URI}`, {
                limit: limit,
                skip: skip
            });

            if (response) {
                for (let i = 0; i < response.length; i++) {
                    chains.push(response[i]);
                }

                skip += limit;
                hasMore = response.length > 0;
            } else {
                hasMore = false
            }
        } while (hasMore);
    } catch (err) {
        console.error(err)
    }
    return chains;
}

export async function heartbeatAuth(token: string): Promise<any> {
    try {
        let response = await ApiWrapper.get(`${SCHEDULE_API_BASE}${HEARTBEAT_AUTH_URI}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        return response.data.responseData;
    } catch (error) {
        console.log(error);
    }

}
