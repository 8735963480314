export const MappingRowStyles: { [name: string]: any } = {
  saveButton: {
    marginLeft: "0.5em"
  },
  existingIdRow: {
    backgroundColor: "auto"
  },
  newIdRow: {
    backgroundColor: "#ffe345"
  },
  centerText: {
    textAlign: 'center'
  }
}