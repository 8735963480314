import React from "react"
import { JobConfigList } from "./job-config-list"
import { Box, Button, FormControl,TablePagination, TextField } from "@material-ui/core"
import { IJobConfig } from "models/IJobConfig"
import { JobConfigApiService } from "services/JobConfigApiService"
import { getChains } from "services/ScheduleDataApiService"
import { JobConfigSortIndex, JobConfigSortMethod } from "services/Api/JobConfigServiceConstants"
import { IChain } from "../models/IChain"
import { Autocomplete, Pagination } from "@material-ui/lab"
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions"
import { styles } from "../css/shared-css";
import { ICompany } from "models/MCP/ICompany"
import { McpApiService } from "services/McpApiService"
import { IJobExecutionLog } from "models/IJobExecutionLog"
import { JobConfigSearchParam } from "models/JobConfigSearchParams"

export type JobConfigViewProps = {}
export type JobConfigViewState = {
  jobConfigs: IJobConfig[]
  integrationType: string,
  vendorType: string,
  chain: IChain | null,
  company: ICompany | null,
  isLoadingChain: boolean,
  isLoadingCompany: boolean,
  chains: IChain[],
  companies: ICompany[],
  integrationTypes: string[],
  vendorTypes: string[],
  hasLoadedChains: boolean,
  hasLoadedCompanies: boolean,
  currentPage: number,
  pageSize: number,
  totalPages: number,
  totalJobConfigs: number,
  jobLogs: IJobExecutionLog[],
}


export class JobConfigView extends React.Component<JobConfigViewProps, JobConfigViewState> {

  state: JobConfigViewState = {
    jobConfigs: [],
    integrationType: '',
    vendorType: '',
    chains:[],
    companies:[],
    integrationTypes: [],
    vendorTypes: [],
    isLoadingChain: false,
    isLoadingCompany: false,
    chain: null,
    company:  null,
    hasLoadedChains: false,
    hasLoadedCompanies: false,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    totalJobConfigs: 0,
    jobLogs: []
  }


  async _setup(): Promise<void> {
    await this.LoadPagedJobConfigs()
    this.setState({ integrationTypes: await JobConfigApiService.GetIntegrationTypes() })
    this.setState({ vendorTypes: await JobConfigApiService.GetVendorTypes() })
    this.setState({ chains: await getChains() })
    this.setState({ companies: await McpApiService.GetUserCompanies() })
    this.AddChainsToJobConfigs()
    this.AddCompaniesToJobConfigs()
  }

  async LoadPagedJobConfigs(): Promise<void> {

    let searchParams = new JobConfigSearchParam({
        sortIndex:  JobConfigSortIndex.SAVED_AT,
        sortMethod: JobConfigSortMethod.DESCENDING,
        pageSize: this.state.pageSize,
        currentPage: this.state.currentPage,
        integrationType: this.state.integrationType,
        vendorType: this.state.vendorType,
        chainId: this.state.chain?.chainId,
        companyId: this.state.company?.id
    
    });
    
    let pagedConfigs = await JobConfigApiService.GetJobConfigs(
      searchParams
    )
    this.setState({
      jobConfigs: pagedConfigs.jobConfigs,
      currentPage: pagedConfigs.currentPage,
      totalPages: pagedConfigs.pageCount,
      totalJobConfigs: pagedConfigs.totalJobConfigs
      })
    
    this.AddChainsToJobConfigs()
    this.AddCompaniesToJobConfigs()
  }

  async HandleRowsPerPageChange(rows: number): Promise<void> {
    await this.UpdateRowsPerPage(rows)
    await this.LoadPagedJobConfigs()
  }

  async UpdateRowsPerPage(rows: number): Promise<void> {
    this.setState({
      pageSize: rows,
      currentPage: 0
    })
  }

  async HandlePageChange(page:number): Promise<void> {
    await this.UpdatePageForPagination(page)
    await this.LoadPagedJobConfigs();
  }

  async UpdatePageForPagination(page: number): Promise<void> {
    this.setState({
      currentPage: page
    })
  }

  AddChainsToJobConfigs(): void {
    const updatedJobConfigs = this.state.jobConfigs
    updatedJobConfigs.forEach((jobConfig) => (jobConfig.chain = this.FindChainByID(parseInt(jobConfig.chainId))))
    this.setState({ jobConfigs: updatedJobConfigs })
  }

  AddCompaniesToJobConfigs(): void {
    const updatedJobConfigs = this.state.jobConfigs
    updatedJobConfigs.forEach((jobConfig) => (jobConfig.company = this.FindCompanyByID(jobConfig.companyId)))
    this.setState({ jobConfigs: updatedJobConfigs})
  }

  FindChainByID(chainId: number): IChain | null {
    const chain = this.state.chains.find((chain) => chain.chainId === chainId)
    if (chain === undefined) {
      return null
    }
    return chain
  }

  componentDidMount(): void {
    this._setup()
  }  

      FindCompanyByID(companyId: string): ICompany | null {
    const company = this.state.companies.find((company) => company.id === companyId)
    if (company === undefined) {
      return null
    }
    return company
  }
  componentWillUnmount(): void {}

  async search(_event: any): Promise<void> {
    try {
        await this.UpdatePageForPagination(0);
        await this.LoadPagedJobConfigs();        
    }
    catch (ex) {
      
        }
    }

  render(): React.ReactNode {
    return (
      <>
        <TablePagination
          colSpan={6}
          ActionsComponent={TablePaginationActions}
          count={this.state.totalJobConfigs}
          rowsPerPage={this.state.pageSize}
          page={this.state.currentPage}
          SelectProps={{
            inputProps: {
              "aria-label": "configs per page"
            },
            native: false
          }}
          onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
            this.HandlePageChange(page)
          }}
          onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            this.HandleRowsPerPageChange(parseInt(event.target.value, 10))
          }}
        />

<Box style={styles.box}>

<FormControl style={styles.formControl} >
                  <Autocomplete
                      id="integration-type"                      
                      options={this.state.integrationTypes}
                      autoSelect={true}
                      onChange={(_event: any, value: any) => this.setState.bind(this)({
                        integrationType: value as string,
                       
                    })}
                      renderInput={params =>
                          <TextField {...params} style={styles.fullLength} label="Integration Type" variant="outlined" />
                      }
                  />
                  
              </FormControl>

      <FormControl style={styles.formControl} >
                  <Autocomplete
                      id="vendor-type"
                      loading={this.state.isLoadingChain}
                      options={this.state.vendorTypes}
                      autoSelect={true}
                      onChange={(_event: any, value: any) => this.setState.bind(this)({
                        vendorType: value as string
                    })}
                      renderInput={params =>
                          <TextField {...params} style={styles.fullLength} label="Vendor Type" variant="outlined" />
                      }
                  />
              </FormControl>
      </Box>

          <Box style={styles.box}>
              <FormControl style={styles.formControl} >
                  <Autocomplete
                      id="chain"
                      loading={this.state.isLoadingChain}
                      options={this.state.chains}
                      getOptionLabel={option => option.chainName}
                      autoSelect={true}
                      onChange={(_event: any, value: IChain | null) => this.setState.bind(this)({
                          chain: value,
                          
                      })}
                      renderInput={params =>
                          <TextField {...params} style={styles.fullLength} label="Chain" variant="outlined" />
                      }
                  />
                  
              </FormControl>
          
              <FormControl style={styles.formControl} >
                  <Autocomplete
                  id="company"
                  value={this.state.company}
                  loading={this.state.isLoadingCompany}
                  options={this.state.companies}
                  getOptionLabel={(option) => option.name}
                  autoSelect={true}
                  onChange={(_event: any, value: ICompany | null) => this.setState.bind(this)({
                    company: value,

                })}
                  renderInput={(params) => (
                      <TextField {...params} style={styles.fullLength} label="Company" variant="outlined" />
                  )}
                  />
              </FormControl>
          </Box>
                
          <Box style={styles.box}>
                    <Button variant="contained" color="primary" onClick={(e) => this.search(e)}>
                        Search
                    </Button>
          </Box>        
        <Box margin="1em">
          <JobConfigList
            items={this.state.jobConfigs}
          />
           {
          this.state.jobConfigs.length < 1
          &&
          <h1>No Results</h1>
         }
        </Box>
       
        <Pagination
          count={this.state.totalPages}
          page={this.state.currentPage+1}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            this.HandlePageChange(value-1)
          }}
        />
      </>
    )
  }
}
