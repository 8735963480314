import React from "react"
import {
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Link,
  FormLabel,
  FormHelperText,
  OutlinedInput
} from "@material-ui/core"
import { IJobConfig } from "models/IJobConfig"
import { JobConfigApiService } from "services/JobConfigApiService"
import { IChain } from "models/IChain"
import { IScheduleMessage } from "models/IScheduleMessage"
import * as JobConfigDestinations from "models/constants/JobConfigDestinationConstants"
import { getChains } from "services/ScheduleDataApiService"
import { Autocomplete } from "@material-ui/lab"
import { IJobConfigSchedule } from "models/IJobConfigSchedule"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { ConfirmDialog } from "../components/ConfirmDialog"
import { JOBCONFIGINDEX } from "services/Api/JobConfigServiceConstants"
import { styles } from "../css/shared-css"
import WithErrorHandling from "../components/error-banner"
import { DestinationCheckbox } from "../components/job-config-destination-checkbox"
import { PasswordField } from "../components/PasswordField"
import { ICompany } from "models/MCP/ICompany"
import { McpApiService } from "services/McpApiService"
import { CronStringControl } from "components/CronString"

export type JobConfigEditProps = {
  match: {
    params: {
      id: string
    }
  }
}

export type JobConfigEditState = {
  isLoadingChain: boolean
  isLoadingCompany: boolean
  chains: IChain[]
  companies: ICompany[]
  integrationTypes: string[]
  vendorTypes: string[]
  isLegacyActive: boolean
  isNextGenActive: boolean
  isTimeActive: boolean
  isWalletActive: boolean
  jobConfig: IJobConfig
  confirmDeleteOpen: boolean
  errors: {}
  jobConfigSchedule: IJobConfigSchedule
  showPassword: boolean
  showError: boolean
  errorMessage: string
  errorChain: boolean
  errorCompany: boolean
  errorCron: boolean
  configEditEnabled: boolean
  originalConfigData: string
  confirmUpdateOpen: boolean
  runNowOpen: boolean
  runNowDisabled: boolean
  updateButtonEnabled: boolean
  hasLoadedChains: boolean
  hasLoadedCompanies: boolean
}

export class JobConfigEdit extends React.Component<JobConfigEditProps, JobConfigEditState> {
  state: JobConfigEditState = {
    isLoadingChain: true,
    isLoadingCompany: true,
    chains: [],
    companies: [],
    integrationTypes: [],
    vendorTypes: [],
    isLegacyActive: false,
    isNextGenActive: false,
    isTimeActive: false,
    isWalletActive: false,
    jobConfig: {
      chain: null,
      chainId: "",
      company: null,
      companyId: "",
      data: "",
      username: "",
      password: "",
      deleted: false,
      description: "",
      id: "",
      intType: "",
      message: "",
      savedAt: new Date(0),
      vendorType: "",
      userId: "",
      destinations: [],
      requiresCredentials: false,
      lastRunJobStatus: "",
      createdAt: new Date(0)
    },
    errors: {},
    jobConfigSchedule: {
      id: "",
      startDate: new Date(),
      endDate: new Date(),
      cron: "",
      timingType: 2
    },
    confirmDeleteOpen: false,
    showPassword: false,
    showError: false,
    errorMessage: "",
    errorChain: false,
    errorCompany: false,
    errorCron: false,
    configEditEnabled: false,
    originalConfigData: "",
    confirmUpdateOpen: false,
    runNowOpen: false,
    runNowDisabled: false,
    updateButtonEnabled: false,
    hasLoadedChains: false,
    hasLoadedCompanies: false
  }

  async _setup(): Promise<void> {
    try {
      this.setState({
        jobConfig: {...this.state.jobConfig, ...(await JobConfigApiService.GetJobConfig(this.props.match.params.id))}
      })
      this.setState({ integrationTypes: await JobConfigApiService.GetIntegrationTypes() })
      this.setState({ vendorTypes: await JobConfigApiService.GetVendorTypes() })
      this.setState({ jobConfigSchedule: await JobConfigApiService.GetJobConfigSchedule(this.state.jobConfig.id) })

      await this.loadDestinations()
    } catch (ex) {
      this.setState({ showError: true, errorMessage: "Error while setting up: " + ex.message })
    }
  }

  async loadCompanies(): Promise<void> {
    if (!this.state.hasLoadedCompanies) {
      try {
        this.setState({
          companies: await McpApiService.GetUserCompanies().finally(() => {
            this.setState({ isLoadingCompany: false })
            this.setState({ hasLoadedCompanies: true })
          })
        })
      } catch (ex) {
        this.setState({ showError: true, errorMessage: "Error while setting up: " + ex.message })
      }
    }

    this.setState((prevState) => ({
      jobConfig: {
        ...prevState.jobConfig,
        company: prevState.companies.find((c) => c.id.toString() === prevState.jobConfig.companyId) || null
      }
    }))
    this.setState({ updateButtonEnabled: true })
  }

  async loadCompaniesNG(): Promise<void> {
    this.toggleDestinations(JobConfigDestinations.JOB_CONFIG_DESTINATION_NEXT_GEN_SCHEDULE)
    this.loadCompanies()
  }

  async loadCompaniesWallet(): Promise<void> {
    this.toggleDestinations(JobConfigDestinations.JOB_CONFIG_DESTINATION_WALLET)
    this.loadCompanies()
  }

  async loadChains(): Promise<void> {
    this.toggleDestinations(JobConfigDestinations.JOB_CONFIG_DESTINATION_LEGACY_SCHEDULE)

    if (!this.state.hasLoadedChains) {
      try {
        this.setState({
          chains: await getChains().finally(() => {
            this.setState({ isLoadingChain: false })
            this.setState({ hasLoadedChains: true })
          })
        })
      } catch (ex) {
        this.setState({ showError: true, errorMessage: "Error while setting up: " + ex.message })
      }
    }

    this.setState((prevState) => ({
      jobConfig: {
        ...prevState.jobConfig,
        chain: prevState.chains.find((c) => c.chainId.toString() === prevState.jobConfig.chainId) || null
      }
    }))
    this.setState({ updateButtonEnabled: true })
  }

  async loadDestinations(): Promise<void> {
    this.setState({
      isLegacyActive: this.isDestinationChecked(JobConfigDestinations.JOB_CONFIG_DESTINATION_LEGACY_SCHEDULE),
      isNextGenActive: this.isDestinationChecked(JobConfigDestinations.JOB_CONFIG_DESTINATION_NEXT_GEN_SCHEDULE),
      isTimeActive: this.isDestinationChecked(JobConfigDestinations.JOB_CONFIG_DESTINATION_ON_TIME),
      isWalletActive: this.isDestinationChecked(JobConfigDestinations.JOB_CONFIG_DESTINATION_WALLET)
    })
    if (this.state.isLegacyActive) {
      try {
        this.setState({
          chains: await getChains().finally(() => {
            this.setState({
              isLoadingChain: false,
              hasLoadedChains: true
            })
          })
        })
      } catch (ex) {
        this.setState({ showError: true, errorMessage: "Error while setting up: " + ex.message })
      }
      this.setState((prevState) => ({
        jobConfig: {
          ...prevState.jobConfig,
          chain: prevState.chains.find((c) => c.chainId.toString() === prevState.jobConfig.chainId) || null
        }
      }))
    }

    if (this.state.isNextGenActive) {
      try {
        this.setState({
          companies: await McpApiService.GetUserCompanies().finally(() => {
            this.setState({
              isLoadingCompany: false,
              hasLoadedCompanies: true
            })
          })
        })
      } catch (ex) {
        this.setState({ showError: true, errorMessage: "Error while setting up: " + ex.message })
      }
      this.setState((prevState) => ({
        jobConfig: {
          ...prevState.jobConfig,
          company: prevState.companies.find((c) => c.id.toString() === prevState.jobConfig.companyId) || null
        }
      }))
    }
    if (this.state.isWalletActive) {
      try {
        this.setState({
          companies: await McpApiService.GetUserCompanies().finally(() => {
            this.setState({
              isLoadingCompany: false,
              hasLoadedCompanies: true
            })
          })
        })
      } catch (ex) {
        this.setState({ showError: true, errorMessage: "Error while setting up: " + ex.message })
      }
      this.setState((prevState) => ({
        jobConfig: {
          ...prevState.jobConfig,
          company: prevState.companies.find((c) => c.id.toString() === prevState.jobConfig.companyId) || null
        }
      }))
    }
  }

  isCronStringValid(isValid: boolean): void {
    this.setState(_prevState => ({
      errorCron: !isValid
    }))
  }

  async IsValidConfig(): Promise<boolean> {
    let isValid = true

    if (this.state.errorCron) {
      isValid = false
      this.setState({ 
        showError: true, 
        errorMessage: "Invalid cron string: must fullfill all of below criteria:\n" +
          "Must be a valid Whole number (decimal number not allowed).\n" +
          "Must be between 1 and 7 for week and 1 and 31 for month.\n" 
     })
    }

    if (this.state.jobConfig.destinations.length === 0) {
      this.setState({
        showError: true,
        errorMessage: "Please select a product destination"
      })
      isValid = false
    }
    this.state.jobConfig.destinations.forEach((currentDestination) => {
      if (currentDestination === JobConfigDestinations.JOB_CONFIG_DESTINATION_LEGACY_SCHEDULE) {
        if (this.state.jobConfig.chain === null) {
          this.setState({
            errorChain: true,
            showError: true,
            errorMessage: "Please select a chain for Legacy Schedule"
          })
          isValid = false
        }
      }
      else if (currentDestination === JobConfigDestinations.JOB_CONFIG_DESTINATION_NEXT_GEN_SCHEDULE) {
        if (this.state.jobConfig.company === null) {
          this.setState({
            errorCompany: true,
            showError: true,
            errorMessage: "Please select a company for Next Gen Schedule"
          })
          isValid = false
        }
      }
      else if (currentDestination === JobConfigDestinations.JOB_CONFIG_DESTINATION_WALLET) {
        if (this.state.jobConfig.company === null) {
          this.setState({ 
            errorCompany: true,
            showError: true, 
            errorMessage: "Please select a company for Wallet" 
          })
          isValid = false
        }
      }
    })

    if (this.state.jobConfig.requiresCredentials) {
      if (this.state.jobConfig.username?.length === 0 ||
        this.state.jobConfig.password?.length === 0) {
        this.setState({
          showError: true,
          errorMessage: "Username and password are required for a config of this type."
        })
        isValid = false
      }
    }
    return isValid
  }

  async submitJobData(): Promise<void> {
    try {
      let updatedJobConfig = await JobConfigApiService.UpdateJobConfig(
        this.props.match.params.id,
        this.state.jobConfig
      )
      if (updatedJobConfig === null) {
        // Let user know there was an error
      } else {
        // refresh page
        this.setState({
          jobConfig: {
            ...updatedJobConfig,
            chain: this.state.chains.find((c) => c.chainId.toString() === updatedJobConfig.chainId) || null,
            company: this.state.companies.find((c) => c.id.toString() === updatedJobConfig.companyId) || null,
            requiresCredentials: this.state.jobConfig.requiresCredentials
          }
        })
      }
    } catch (ex) {
      this.setState({
        showError: true,
        errorMessage: "Error while updating the job config: " + ex.response.data.errors[0].ErrorObject
      })
    }
  }

  async submit(_event: any): Promise<void> {
    try {
      if (await this.IsValidConfig()) {
        await this.submitJobData()

        let scheduleMessage = {
          integrationType: this.state.jobConfig.intType,
          vendorType: this.state.jobConfig.vendorType,
          jobConfigSchedule: this.state.jobConfigSchedule
        } as IScheduleMessage

        try {
          let updatedJobConfigSchedule = await JobConfigApiService.UpdateJobConfigSchedule(
            this.props.match.params.id,
            scheduleMessage
          )
          if (updatedJobConfigSchedule === null) {
            // Let user know there was an error
          } else {
            this.setState({
              jobConfigSchedule: {
                ...updatedJobConfigSchedule
              }
            })
          }
        } catch (ex) {
          this.setState({ showError: true, errorMessage: ex.response.data.errors[0].ErrorObject })
        }

        if (this.state.configEditEnabled) this.setState({ configEditEnabled: false })

        if (this.state.updateButtonEnabled) this.setState({ updateButtonEnabled: false })
      }
    } catch (ex) {
      this.setState({
        showError: true,
        errorMessage: "Error while updating: " + ex.response.data.errors[0].ErrorObject
      })
    }
  }

  setPassword(value: any): void {
    this.setState((prevState) => ({
      jobConfig: {
        ...prevState.jobConfig,
        password: value
      },
      updateButtonEnabled: true
    }))
  }

  setCronString(value: any): void {

    this.setState(prevState => ({
      jobConfigSchedule: {
        ...prevState.jobConfigSchedule,
        cron: value
      },
      updateButtonEnabled: true
    }))
  }

  handleClickShowPassword(): void {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  onCompanyChange = (_event: any, selectedCompany: ICompany | null) => {
    if (selectedCompany !== null) {
      this.setState((prevState) => ({
        jobConfig: {
          ...prevState.jobConfig,
          company: selectedCompany,
          companyId: selectedCompany.id
        }
      }))
      this.setState({ updateButtonEnabled: true })
    }
  }

  onChainChange = (_event: any, selectedChain: IChain | null) => {
    if (selectedChain !== null) {
      this.setState((prevState) => ({
        jobConfig: {
          ...prevState.jobConfig,
          chain: selectedChain,
          chainId: selectedChain.chainId.toString()
        }
      }))
      this.setState({ updateButtonEnabled: true })
    }
  }

  setConfirmDeleteOpen(value: boolean): void {
    this.setState({ confirmDeleteOpen: value })
  }

  setConfirmUpdateOpen(value: boolean): void {
    this.setState({ confirmUpdateOpen: value })
  }

  setRunNowOpen(value: boolean): void {
    this.setState({ runNowOpen: value })
  }

  setConfigEditEnabled(): void {
    this.setState({ configEditEnabled: !this.state.configEditEnabled })
    this.setState({ originalConfigData: this.state.jobConfig.data ?? "" })
  }

  handleConfigEditCancelClick(): void {
    this.setState({ configEditEnabled: !this.state.configEditEnabled })
    this.setState((prevState) => {
      let jobConfig = Object.assign({}, prevState.jobConfig)
      jobConfig.data = this.state.originalConfigData
      return { jobConfig }
    })
  }

  setErrorOpen(value: boolean): void {
    this.setState({ showError: value })
  }

  isDestinationChecked(destination: number): boolean {
    let isActive = false

    this.state.jobConfig.destinations.forEach(function (currentDestination) {
      if (currentDestination === destination) {
        isActive = true
      }
    })

    return isActive
  }

  async deleteJobConfig(): Promise<void> {
    try {
      await JobConfigApiService.DeleteJobConfig(this.props.match.params.id)
      window.location.href = JOBCONFIGINDEX
    } catch (ex) {
      this.setState({
        showError: true,
        errorMessage: "Error while deleting the config: " + ex.response.data.errors[0].ErrorObject
      })
    }
  }

  async runNow(): Promise<void> {

    try {
      await JobConfigApiService.PostUnformattedJobConfigToServiceBus({
        destinations: this.state.jobConfig.destinations,
        vendorType: this.state.jobConfig.vendorType,
        integrationType: this.state.jobConfig.intType,
        jobConfigId: this.state.jobConfig.id,
        formattedDataId: ""
      })
      this.state.runNowDisabled = true
    } catch (ex) {
      this.setState({
        showError: true,
        errorMessage: "Error while trying to trigger a run for config: " + ex.response.data.errors[0].ErrorObject
      })
    }

  }

  async componentDidMount(): Promise<void> {
   await this._setup()
  }

  handleConfigTextChange(event: { target: { value: string | null } }): void {
    let config = event.target.value
    this.setState({ updateButtonEnabled: true })
    this.setState((prevState) => {
      let jobConfig = Object.assign({}, prevState.jobConfig)
      jobConfig.data = config
      return { jobConfig }
    })
  }

  toggleDestinations(checkedDestination: number): void {
    let isNewDestination = true
    let currentDestinations = this.state.jobConfig.destinations
    let newDestinations: number[] = []

    currentDestinations.forEach(function (currentDestination) {
      if (currentDestination === checkedDestination) {
        isNewDestination = false
      } else {
        newDestinations.push(currentDestination)
      }
    })

    if (isNewDestination) {
      newDestinations.push(checkedDestination)
    }

    this.setState((prevState) => ({
      jobConfig: {
        ...prevState.jobConfig,
        destinations: newDestinations
      }
    }))
    this.setState({ updateButtonEnabled: true })

    switch (checkedDestination) {
      case JobConfigDestinations.JOB_CONFIG_DESTINATION_LEGACY_SCHEDULE: {
        this.setState({ isLegacyActive: !this.state.isLegacyActive })
        break
      }
      case JobConfigDestinations.JOB_CONFIG_DESTINATION_NEXT_GEN_SCHEDULE: {
        this.setState({ isNextGenActive: !this.state.isNextGenActive })
        break
      }
      case JobConfigDestinations.JOB_CONFIG_DESTINATION_ON_TIME: {
        this.setState({ isTimeActive: !this.state.isTimeActive })
        break
      }
      case JobConfigDestinations.JOB_CONFIG_DESTINATION_WALLET: {
        this.setState({ isWalletActive: !this.state.isWalletActive })
        break
      }
    }
  }

  renderCancelEditButton(): React.ReactNode {
    let tag

    if (this.state.configEditEnabled) {
      tag = <Button style={styles.configButton} color="primary" variant="contained" onClick={this.handleConfigEditCancelClick.bind(this)}>Cancel</Button>
    }
    else {
      tag = <Button style={styles.configButton} color="primary" variant="contained" onClick={this.setConfigEditEnabled.bind(this)}>Edit</Button>
    }

    return tag
  }

  render(): React.ReactNode {
    const { isLegacyActive, isNextGenActive, isWalletActive } = this.state
    let configButton = this.renderCancelEditButton()

    return (
      <Paper>
        <WithErrorHandling
          open={this.state.showError}
          setOpen={this.setErrorOpen.bind(this)}
          errorMessage={this.state.errorMessage}
        />
        <Box style={styles.box}>
          <FormControl style={styles.formControl}>
            <FormLabel style={styles.formLabel}>Product</FormLabel>
            <table>
              <tbody>
                <tr>
                  <td>
                    <DestinationCheckbox
                      checkboxText="Legacy Schedule"
                      toggleDestination={this.loadChains.bind(this)}
                      defaultChecked={this.state.isLegacyActive}
                      destination={JobConfigDestinations.JOB_CONFIG_DESTINATION_LEGACY_SCHEDULE}
                    />
                  </td>
                  <td>
                    <DestinationCheckbox
                      checkboxText="Next Gen Schedule"
                      toggleDestination={this.loadCompaniesNG.bind(this)}
                      defaultChecked={this.state.isNextGenActive}
                      destination={JobConfigDestinations.JOB_CONFIG_DESTINATION_NEXT_GEN_SCHEDULE}
                    />
                  </td>
                  <td>
                    <DestinationCheckbox
                      checkboxText="Time FTP"
                      toggleDestination={this.toggleDestinations.bind(this)}
                      defaultChecked={this.state.isTimeActive}
                      destination={JobConfigDestinations.JOB_CONFIG_DESTINATION_ON_TIME}
                    />
                  </td>
                  <td>
                    <DestinationCheckbox
                      checkboxText="Wallet FTP"
                      toggleDestination={this.loadCompaniesWallet.bind(this)}
                      defaultChecked={this.state.isWalletActive}
                      destination={JobConfigDestinations.JOB_CONFIG_DESTINATION_WALLET}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </FormControl>
        </Box>
        {isLegacyActive && (
          <Box style={styles.box}>
            <FormControl style={styles.fullLength}>
              <Autocomplete
                id="chain"
                value={this.state.jobConfig.chain}
                loading={this.state.isLoadingChain}
                options={this.state.chains}
                getOptionLabel={(option) => option.chainName}
                autoSelect={true}
                onChange={this.onChainChange}
                renderInput={(params) => (
                  <TextField {...params} style={styles.fullLength} label="Chain" variant="outlined" />
                )}
              />
              {this.state.errorChain ? <FormHelperText>Error</FormHelperText> : null}
            </FormControl>
          </Box>
        )}
        {(isNextGenActive || isWalletActive) && (
          <Box style={styles.box}>
            <FormControl style={styles.fullLength}>
              <Autocomplete
                id="company"
                value={this.state.jobConfig.company}
                loading={this.state.isLoadingCompany}
                options={this.state.companies}
                getOptionLabel={(option) => option.name}
                autoSelect={true}
                disabled={this.state.companies === null || this.state.companies.length <= 1}
                onChange={this.onCompanyChange}
                renderInput={(params) => (
                  <TextField {...params} style={styles.fullLength} label="Company" variant="outlined" />
                )}
              />
              {this.state.errorCompany ? <FormHelperText>Error</FormHelperText> : null}
            </FormControl>
          </Box>
        )}
        <Box style={styles.box}>
          <FormControl style={styles.formControl}>
            <InputLabel id="integration-type-label">Integration Type</InputLabel>
            <Select
              labelId="integration-type-label"
              id="integration-type"
              value={this.state.jobConfig.intType}
              disabled={true}
            >
              {this.state.integrationTypes.map((value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={styles.formControl}>
            <InputLabel id="vendor-type-label">Vendor Type</InputLabel>
            <Select
              labelId="vendor-type-label"
              id="vendor-type"
              value={this.state.jobConfig.vendorType}
              disabled={true}
            >
              {this.state.vendorTypes.map((value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box style={styles.box}>
          <TextField
            id="jc-description"
            label="Description"
            multiline
            rows={4}
            style={styles.fullLength}
            variant="outlined"
            onChange={(e) => {
              let description = e.target.value
              this.setState((prevState) => ({
                jobConfig: {
                  ...prevState.jobConfig,
                  description: description
                }
              }))
              this.setState({ updateButtonEnabled: true })
            }}
            value={this.state.jobConfig.description}
          />
        </Box>
        <Box style={styles.configOptionRow}>
          <Box style={styles.configOptionRowElement}>
            <KeyboardDatePicker
              format="MM/dd/yyyy"
              label="Schedule start date"
              value={this.state.jobConfigSchedule.startDate}
              onChange={(e) => {
                let dateString = e?.toDateString()
                if (dateString !== undefined) {
                  let startDate = new Date(dateString)
                  this.setState((prevState) => ({
                    jobConfigSchedule: {
                      ...prevState.jobConfigSchedule,
                      startDate: startDate
                    }
                  }))
                  this.setState({ updateButtonEnabled: true })
                }
              }}
              animateYearScrolling
            />
          </Box>
          <Box style={styles.configOptionRowElement}>
            <KeyboardDatePicker
              format="MM/dd/yyyy"
              label="Schedule end date"
              value={this.state.jobConfigSchedule.endDate}
              onChange={(e) => {
                let dateString = e?.toDateString()
                if (dateString !== undefined) {
                  let endDate = new Date(dateString)
                  this.setState((prevState) => ({
                    jobConfigSchedule: {
                      ...prevState.jobConfigSchedule,
                      endDate: endDate
                    }
                  }))
                  this.setState({ updateButtonEnabled: true })
                }
              }}
              animateYearScrolling
            />
          </Box>
			<CronStringControl
            Cron={this.state.jobConfigSchedule.cron}
            setCronString={this.setCronString.bind(this)}
            isCronValid={this.isCronStringValid.bind(this)}
          />
        </Box>
        {this.state.jobConfig.requiresCredentials && (
          <Box style={styles.configOptionRow}>
            <Box style={styles.configOptionRowElement}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                <OutlinedInput
                  label="Username"
                  id="user"
                  value={this.state.jobConfig.username}
                  onChange={(e) => {
                    let updatedUsername = e.target.value
                    this.setState((prevState) => ({
                      jobConfig: {
                        ...prevState.jobConfig,
                        username: updatedUsername
                      },
                      updateButtonEnabled: true
                    }))
                  }}
                  type={"text"}
                />
              </FormControl>
            </Box>
            <Box style={styles.configOptionRowElement}>
              <PasswordField
                handleClickShowPassword={this.handleClickShowPassword.bind(this)}
                setPassword={this.setPassword.bind(this)}
                password={this.state.jobConfig.password}
                showPassword={this.state.showPassword}
              />
            </Box>
          </Box>
        )}
        <Box style={styles.editConfigBox}>
          {configButton}
          <textarea
            disabled={!this.state.configEditEnabled}
            style={styles.configText}
            name="configText"
            value={this.state.jobConfig.data ?? ""}
            onChange={this.handleConfigTextChange.bind(this)}
            spellCheck="false"
          />
        </Box>
        <Box style={styles.box}>
          <TextField
            id="saved-at"
            label="Last saved"
            type="datetime-local"
            variant="outlined"
            disabled={true}
            value={this.state.jobConfig.savedAt?.toISOString().replace("Z", "")}
          />
        </Box>
        <Box style={styles.box}>
          <Button onClick={() => this.setConfirmUpdateOpen(true)} disabled={!this.state.updateButtonEnabled}>
            Update
          </Button>
          <ConfirmDialog
            title="Update Configuration?"
            open={this.state.confirmUpdateOpen}
            setOpen={this.setConfirmUpdateOpen.bind(this)}
            onConfirm={this.submit.bind(this)}
          >
            Are you sure you want to update this configuration?
          </ConfirmDialog>
          <Button>
            <Link href="/">Cancel</Link>
          </Button>
          <Button onClick={() => this.setConfirmDeleteOpen(true)}>Delete</Button>
          <ConfirmDialog
            title="Delete Configuration?"
            open={this.state.confirmDeleteOpen}
            setOpen={this.setConfirmDeleteOpen.bind(this)}
            onConfirm={this.deleteJobConfig.bind(this)}
          >
            Are you sure you want to delete this configuration?
          </ConfirmDialog>
          <Button onClick={() => this.setRunNowOpen(true)} disabled={this.state.runNowDisabled}>
            Run Now
          </Button>
          <ConfirmDialog
            title="Run now"
            open={this.state.runNowOpen}
            setOpen={this.setRunNowOpen.bind(this)}
            onConfirm={this.runNow.bind(this)}
          >
            Are you sure you want to run this configuration?
          </ConfirmDialog>
        </Box>
      </Paper>
    )
  }
}
