export const COMMUNITY_URI: string = "Community"
export const COMPANY_URI: string = "Company"
export const COMMUNITIES_BY_COMPANY_ID = (companyId: string) => `Company/${companyId}/Community`
export const DEPARTMENTS_BY_COMMUNITY_ID = (communityId: string) => `Community/${communityId}/Department`
export const EMP_JOB_CODES_BY_COMMUNITY_ID = (communityId: string) => `EmployeeJobCode/Community/${communityId}`
export const FLSA_CODES_BY_COMMUNITY_ID = (communityId: string) => `FlsaMapping/Community/${communityId}`
export const PUNCH_JOB_CODES_BY_COMMUNITY_ID = (communityId: string) => `PunchJobCode/Community/${communityId}`
export const PUNCH_PAY_CODES_BY_COMMUNITY_ID = (communityId: string) => `PunchPayCode/Community/${communityId}`
export const PUNCH_PAY_CODES = () => `resource/v1/PayCode`
export const FACILITY_MAPS_BY_COMPANY = (companyId: string) => `FacilityIdMap/Company/${companyId}`
