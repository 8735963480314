//Currently this is all of our shared CSS. This should be moved at somepoint to a better solution for handling CSS.

export const styles: { [name: string]: React.CSSProperties } = {
    formControl: {
        marginRight: '1%',
        minWidth: '49%'
    },
    formLabel: {
        paddingBottom: '10px'
    },
    fullLength: {
        width: '100%'
    },
    box: {
        padding: '1em'
    },
    configOptionRow: {
        padding: '1em',
        display: 'flex'
    },
    configOptionRowElement: {
        paddingRight: '1em'
    },
    configOptionRowTextElement: {
        paddingRight: '1em',
        paddingTop: '.8em'
    },
    textField: {
        marginLeft: 1,
        marginRight: 1,
        width: 200,
    },
    configText: {
        minHeight: 600,
        minWidth: '95%',
        backgroundColor: "GhostWhite"
    },
    configButton: {
        padding: '1em',
        height: 50,
        width: 200,
        marginBottom: 5
    },
    editConfigBox: {
        padding: '1em',
        display: 'flex',
        flexDirection: 'column'
    },
    horizontalScrollingDiv: {
        overflowX: 'scroll'
    },
    logoSmall: {
      marginTop: '25px',
      paddingTop: '1em',
      textAlign: 'center',
      width: 250
    },
};
