import React from "react"
import { IJobConfig } from "models/IJobConfig"
import { TableContainer, Paper, TableHead, TableRow, TableCell, Table, TableBody, IconButton } from "@material-ui/core"
import { Link } from "react-router-dom"
import { Edit } from "@material-ui/icons"
import { JobConfig } from "../models/JobConfig"

export type JobConfigListProps = {
  items: IJobConfig[]  
}

export type JobConfigListState = {
}
export class JobConfigList extends React.Component<JobConfigListProps, JobConfigListState> {
  _renderJobConfig(jobConfig: IJobConfig): React.ReactNode {
    return (
      <TableRow key={jobConfig.id}>
        <TableCell>
          <Link to={`/edit/${jobConfig.id}`}>
            <IconButton>
              <Edit />
            </IconButton>
          </Link>
        </TableCell>
        <TableCell>{jobConfig.chain != null && jobConfig.company != null ?
            jobConfig.chain.chainName + '/' + jobConfig.company.name
          : 
            jobConfig.chain ? jobConfig.chain.chainName : jobConfig.company ? jobConfig.company.name : 'Time SFTP'}</TableCell>
        <TableCell>{jobConfig.description}</TableCell>
        <TableCell>{jobConfig.intType}</TableCell>
        <TableCell>{jobConfig.vendorType}</TableCell>
        <TableCell>{jobConfig.savedAt}</TableCell>
        <TableCell>{jobConfig.lastRunJobStatus}</TableCell>
        <TableCell>{jobConfig.createdAt}</TableCell>
      </TableRow>
    )
  }

  render(): React.ReactNode {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Configure</TableCell>
              <TableCell>Chain Name / Company Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Integration Type</TableCell>
              <TableCell>Vendor Type</TableCell>
              <TableCell>Last Modified</TableCell>
              <TableCell>Run Status</TableCell>
              <TableCell>Created Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.items
              ? this.props.items.map((jobConfig) => this._renderJobConfig(jobConfig))
              : this._renderJobConfig(new JobConfig(""))
              }
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}
