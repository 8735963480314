import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

type ConfirmDialogType = {
    title: any;
    children: any;
    open: any;
    setOpen: any;
    onConfirm: any;
};

export const ConfirmDialog = (props: ConfirmDialogType) => {
  const { title, children, open, setOpen, onConfirm} = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="primary">
          No
        </Button>
            <Button 
                variant="contained"
                color="default"
                onClick={() => {
                    onConfirm();
                    setOpen(false);
                }}>
                Yes
            </Button>
      </DialogActions>
    </Dialog>
  );
};