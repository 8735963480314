import { JobConfigSortIndex, JobConfigSortMethod } from "services/Api/JobConfigServiceConstants";
import { IJobConfigSearchParams } from "./IJobConfigSearchParams";

export class JobConfigSearchParam implements IJobConfigSearchParams
{
    sortIndex: JobConfigSortIndex;
    sortMethod: JobConfigSortMethod;
    pageSize: number;
    currentPage: number;
    integrationType: string;
    vendorType: string;
    chainId?: number;
    companyId?: string;

    constructor(jsonBlob: any)
    {
        this.sortIndex = jsonBlob.sortIndex;
        this.sortMethod = jsonBlob.sortMethod;
        this.pageSize = jsonBlob.pageSize;
        this.currentPage = jsonBlob.currentPage;
        this.integrationType = jsonBlob.integrationType;
        this.vendorType = jsonBlob.vendorType;
        this.chainId = jsonBlob.chainId;
        this.companyId = jsonBlob.companyId;
    }

}