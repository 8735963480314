import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useAuth0 } from "./Auth0Provider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    },
    logo: {
      marginTop: "75px",
      textAlign: "center"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff"
    }
  })
)

export const LoadingPage = () => {
  const classes = useStyles()
  const { loading } = useAuth0()

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <img
          src="https://www.onshift.com/hubfs/assets/dev/logos/onshift-logo-light.svg"
          alt="OnShift, Inc."
          height="80%"
          width="80%"
        />
        <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  )
}
