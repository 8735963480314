import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText } from '@material-ui/core';

type RedirectDialogType = {
    title: any;
    message: any;
    open: any;
    setOpen: any;
    redirectLink: string;
};

export const RedirectDialog = (props: RedirectDialogType) => {
  const { title, message, open, setOpen, redirectLink} = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="redirect-dialog"
    >
      <DialogTitle id="redirect-dialog">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
            <Button 
                variant="contained"
                color="default"
                onClick={() => {
                    setOpen(false);
                    window.location.href = redirectLink;
                }}>
                Ok
            </Button>
      </DialogActions>
    </Dialog>
  );
};