import React, { FC, useContext, useEffect, useState } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client"
import {
  RedirectLoginResult,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  getIdTokenClaimsOptions,
  IdToken,
  User
} from "@auth0/auth0-spa-js/dist/typings/global"
import { AUTH_AUDIENCE, AUTH_CLIENT_ID, AUTH_DOMAIN } from "services/Api/Auth0Constants"
import AxiosApi from "services/AxiosApi"

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export const AUTH_ROLES = "https://denali.onshift.com/roles"

if (process.env.NODE_ENV !== "test" && (!AUTH_AUDIENCE || !AUTH_CLIENT_ID || !AUTH_DOMAIN)) {
  throw new Error("Misconfigured Auth Provider")
}

export interface Auth0ContextProps {
  isAuthenticated: boolean
  user: any
  loading: boolean
  useRefreshTokens: boolean
  handleRedirectCallback(): Promise<RedirectLoginResult>
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken | undefined>
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  logout(o?: LogoutOptions): void
}

export interface Auth0ProviderProps {
  domain: string
  client_id: string
  redirect_uri: string
  scope: string
  onRedirectCallback: (appState: any) => void
  audience: string
  useRefreshTokens: boolean
  cacheLocation: "memory" | "localstorage" | undefined
}

export const hasSchedulerRole = (user: any) => {
  const role = user[AUTH_ROLES]?.length !== undefined && user[AUTH_ROLES].length === 1 ? user[AUTH_ROLES][0] : ""
  return role === "scheduler"
}

export const Auth0Context = React.createContext<Auth0ContextProps | null>(null)
// eslint-disable-next-line prefer-const
export let useAuth0 = () => useContext(Auth0Context) as Auth0ContextProps
export const Auth0Provider: FC<Auth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...props
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
  const [user, setUser] = useState<User | undefined>()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook = await createAuth0Client(props)
        setAuth0(auth0FromHook)
        if (window.location.search.includes("code=")) {
          const { appState } = await auth0FromHook.handleRedirectCallback()
          onRedirectCallback(appState)
        }
        const isAuthenticated = await auth0FromHook.isAuthenticated()

        setIsAuthenticated(isAuthenticated !== undefined && isAuthenticated)

        if (isAuthenticated) {
          const user = await auth0FromHook.getUser()
          setUser(user)

          const token = await auth0FromHook.getTokenSilently()
          AxiosApi.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : null
        }

        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const handleRedirectCallback = async (): Promise<RedirectLoginResult> => {
    setLoading(true)
    const result = await auth0Client!.handleRedirectCallback()
    const auth0User = await auth0Client!.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(auth0User)
    return result
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated: isAuthenticated !== undefined && isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        useRefreshTokens: true,
        getIdTokenClaims: (o: getIdTokenClaimsOptions | undefined) => auth0Client!.getIdTokenClaims(o),
        loginWithRedirect: (o: RedirectLoginOptions) => auth0Client!.loginWithRedirect(o),
        getTokenSilently: (o: GetTokenSilentlyOptions | undefined) => auth0Client!.getTokenSilently(o),
        getTokenWithPopup: (o: GetTokenWithPopupOptions | undefined) => auth0Client!.getTokenWithPopup(o),
        logout: (o: LogoutOptions | undefined) => auth0Client!.logout(o)
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
