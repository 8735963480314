import { Paper, Box, FormControl, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { styles } from "css/shared-css"
import { MapTypeEnum } from "models/MCP/DenaliMaps"
import { ICommunity } from "models/MCP/ICommunity"
import { ICompany } from "models/MCP/ICompany"
import React, { FC, useEffect, useState } from "react"
import { McpApiService } from "services/McpApiService"
import { CompanySelector } from "./CompanySelector"

export interface CommunitySelectorProps {
  company: ICompany | null
  setCompany: (company: ICompany | null) => void
  type: any
  community: ICommunity | null
  setCommunity: (community: ICommunity | null) => void
}

export const CommunitySelector: FC<CommunitySelectorProps> = ({
  company,
  setCompany,
  type,
  community,
  setCommunity
}) => {
  const [userCommunities, setUserCommunities] = useState<ICommunity[]>([])
  const [isLoadingCommunity, setIsLoadingCommunity] = useState(false)

  const fetchCommunities = async (companyId: string) => {
    setIsLoadingCommunity(true)
    var userAvailableCommmunities = await McpApiService.GetUserCommunitiesByCompany(companyId).finally(() => {
      setIsLoadingCommunity(false)
    })
    setUserCommunities(userAvailableCommmunities ?? [])
    if (userAvailableCommmunities.length === 1) {
      setCommunity(userAvailableCommmunities[0])
    }
  }

  useEffect(() => {
    if (company !== null) {
      fetchCommunities(company.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  return (
    <Paper>
      <CompanySelector company={company} setCompany={setCompany} />

      {type !== MapTypeEnum.FacilityMapping && (
        <Box style={styles.box}>
          <FormControl style={styles.fullLength}>
            <Autocomplete
              id="community"
              value={community}
              loading={isLoadingCommunity}
              options={userCommunities}
              getOptionLabel={(option) => option.name}
              disabled={company == null}
              onChange={(e, value) => setCommunity(value)}
              renderInput={(params) => (
                <TextField {...params} style={styles.fullLength} label="Community" variant="outlined" />
              )}
            />
          </FormControl>
        </Box>
      )}
    </Paper>
  )
}
