import React from "react"
import { FormControl, InputAdornment, InputLabel, OutlinedInput, IconButton } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"


export function PasswordField(props:any) {
    const handleClickShowPassword = props.handleClickShowPassword
    const setPassword = props.setPassword
    const password = props.password
    const showPassword = props.showPassword

    const handleChange = (_prop:any) => (event:any) => {
        setPassword(event.target.value)
    }

    return (
    <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput 
            label="Password"
            id="pass"
            value={password}
            onChange={handleChange('password')}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                }
            />
    </FormControl>
    )
}