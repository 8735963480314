import { IJobConfig } from "models/IJobConfig";
import { IChain } from "./IChain";
import { ICompany } from "./MCP/ICompany";

export class JobConfig implements IJobConfig
{
    key: string
    id: string  // Needs to be Guid
    description: string
    savedAt: Date  // Maybe make a custom datetime field; probably best to wait until we use this
    userId: string
    chainId: string  // Needs to be Chain from database
    chain: IChain
    companyId: string
    company: ICompany
    intType: string
    vendorType: string  
    data: string
    username: string
    password: string
    message: string
    deleted: boolean
    destinations: number[]
    requiresCredentials: boolean
    lastRunJobStatus: string
    createdAt: Date

    constructor(jsonBlob: any) {
        this.id = jsonBlob.id
        this.key = this.id
        this.description = jsonBlob.description
        this.savedAt = jsonBlob.savedAt
        this.userId = jsonBlob.userId
        this.chainId = jsonBlob.chainId
        this.companyId = jsonBlob.companyId
        this.intType = jsonBlob.intType
        this.vendorType = jsonBlob.vendorType
        this.data = jsonBlob.data
        this.username = jsonBlob.username
        this.password = jsonBlob.password
        this.message = jsonBlob.message
        this.deleted = jsonBlob.deleted
        this.createdAt = jsonBlob.createdAt
        this.lastRunJobStatus = jsonBlob.lastRunJobStatus

        this.chain = {
            chainId: jsonBlob.chainId,
            chainName: "",
            chainDescription: ""
        }
        this.company = {
            id: jsonBlob.companyId,
            name: "",
            groupTypeId: "",
            tenantId: ""
        }
        this.destinations = jsonBlob.destinations
        this.requiresCredentials = IsVendorRequiringCredentials(this.vendorType)
    }
}

export function IsVendorRequiringCredentials(vendor: string): boolean{
    let vendorsRequiringCredentials: string[] = [
        "FTP",
        "INFINISOURCE",
        "KRONOS_XML",
        "KRONOS_WORKFORCE",
        "ULTIMATE_UTA",
        "VIVENTIUM",
        "POINT_CLICK_CARE",
        "ULTIMATE_UTA_REST"
    ]
    return vendorsRequiringCredentials.includes(vendor)
}