import { IJobExecutionLog } from "./IJobExecutionLog";

export class JobExecutionLog implements IJobExecutionLog {
    id: string;  // Needs to be Guid
    jobConfigId: string;
    jobId: string;
    startDateTime: string;
    endDateTime: string;
    jobStatus: number;
    integrationType: string;
    vendorType: string;
    displayMesage: string;
    detailedMessage: string;

    constructor(json: any)
    {
        this.id = json.id;
        this.jobConfigId = json.jobConfigId;
        this.jobId = json.jobId;
        this.startDateTime = json.startDateTime;
        this.endDateTime = json.endDateTime;
        this.jobStatus = json.jobStatus;
        this.integrationType = json.integrationType;
        this.vendorType = json.vendorType;
        this.displayMesage = json.displayMesage;
        this.detailedMessage = json.detailedMessage;

    }
}