import React, { useEffect } from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { useAuth0 } from "./Auth0Provider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    },
    logo: {
      marginTop: "75px",
      textAlign: "center"
    }
  })
)

export const LandingPage = () => {
  const classes = useStyles()
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated) {
      loginUser()
    }
  })

  const loginUser = async () => {
    await loginWithRedirect({})
  }

  return <div className={classes.root}>{""}</div>
}
