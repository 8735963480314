import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
type WithErrorHandling = {
    errorMessage: string;
    open: any;
    setOpen: any;
};

export default function WithErrorHandling(withErrorHandling: WithErrorHandling) {
    const { open, setOpen } = withErrorHandling;

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"The application experienced the following error:"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {withErrorHandling.open && <div>{withErrorHandling.errorMessage}</div>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary" autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};