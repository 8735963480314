import { Box, FormControl, InputLabel, Paper, OutlinedInput, Button, CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { AxiosError } from "axios"
import { PasswordField } from "components/PasswordField"
import { styles } from "css/shared-css"
import React from "react"
import { Auth0ApiService } from "services/Auth0Api"
import { OnTimeApiService } from "services/OnTimeApi"
import WithErrorHandling from "../components/error-banner"

export type PaycorAuthLandingProps = {}
export type PaycorAuthLandingState = {
  username: string
  password: string
  showPassword: boolean
  isSubmitting: boolean
  showError: boolean
  errorMessage: string
  invalidCredentials: boolean
  validCredentials: boolean
}

export class PaycorAuthLanding extends React.Component<PaycorAuthLandingState, PaycorAuthLandingProps> {
  state: PaycorAuthLandingState = {
    username: "",
    password: "",
    showPassword: false,
    isSubmitting: false,
    showError: false,
    errorMessage: "",
    invalidCredentials: false,
    validCredentials: false
  }

  async _setup(): Promise<void> {}

  setPassword(value: any): void {
    this.setState({
      password: value
    })
  }

  setOpen(value: boolean): void {
    this.setState({ showError: value })
  }

  handleClickShowPassword(): void {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  async submit(event: any): Promise<void> {
    this.setState({
      isSubmitting: true
    })

    try {
      await Auth0ApiService.Login(this.state.username, this.state.password)
      this.setState({
        validCredentials: true,
        invalidCredentials: false
      })
    } catch (auth0Exception) {
      let auth0Error = auth0Exception as AxiosError
      if (auth0Error.response?.status === 403) {
        try {
          await OnTimeApiService.Login(this.state.username, this.state.password)
          this.setState({
            validCredentials: true,
            invalidCredentials: false
          })
        } catch (onTimeException) {
          let onTimeError = onTimeException as AxiosError
          if (onTimeError.response?.status === 404) {
            this.setState({
              validCredentials: false,
              invalidCredentials: true
            })
          } else {
            this.setState({
              showError: true,
              errorMessage: "Error while validating credentials against OnShift Time: " + onTimeError.response?.data
            })
          }
        }
      } else {
        this.setState({
          showError: true,
          errorMessage: "Error while validating credentials against Auth0: " + auth0Error.response?.data
        })
      }
    }

    this.setState({
      isSubmitting: false
    })
  }

  render(): React.ReactNode {
    return (
      <Paper>
        <WithErrorHandling
          open={this.state.showError}
          setOpen={this.setOpen.bind(this)}
          errorMessage={this.state.errorMessage}
        />
        <div style={styles.logoSmall}>
          <img
            src="https://www.onshift.com/hubfs/assets/dev/logos/onshift-logo-light.svg"
            alt="OnShift, Inc."
            height="80%"
            width="80%"
          />
        </div>
        {this.state.invalidCredentials && !this.state.validCredentials && (
          <Alert severity="error">Invalid username or password.</Alert>
        )}
        {this.state.validCredentials && !this.state.invalidCredentials && (
          <Alert severity="success">Valid credentials.</Alert>
        )}
        <Box style={styles.configOptionRow}>
          <Box style={styles.configOptionRowElement}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
              <OutlinedInput
                label="Username"
                id="user"
                value={this.state.username}
                onChange={(e) => {
                  let updatedUsername = e.target.value as string
                  this.setState({
                    username: updatedUsername
                  })
                }}
                type={"text"}
              />
            </FormControl>
          </Box>
          <Box style={styles.configOptionRowElement}>
            <PasswordField
              handleClickShowPassword={this.handleClickShowPassword.bind(this)}
              setPassword={this.setPassword.bind(this)}
              password={this.state.password}
              showPassword={this.state.showPassword}
            />
          </Box>
        </Box>
        <Box style={styles.box}>
          {this.state.isSubmitting ? (
            <Button variant="contained" color="primary">
              <CircularProgress color="inherit" />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={this.state.username.length === 0 || this.state.password.length === 0}
              onClick={(e) => this.submit(e)}
            >
              Log In
            </Button>
          )}
        </Box>
      </Paper>
    )
  }
}
