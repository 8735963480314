import React from "react"
import { IJobExecutionLog } from "models/IJobExecutionLog"
import { TableContainer, Paper, TableHead, TableRow, TableCell, Table, TableBody, IconButton } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { JobExecutionLog } from "../models/JobExecutionLog"
import { JobExecutionLogService } from "../services/JobExecutionLogService"
import Modal from "react-modal"

let JobStatusNames = ["INIT", "ACTIVE", "DONE", "ERROR"]

export type JobExecutionLogLst = {
  items: IJobExecutionLog[]
  totalPages: number
  PageNumbers: number[]
  searchTerm: string
  pageSize: number
  showModal: boolean
  modalMessage: string
}
export type JobConfigListState = {}

Modal.setAppElement("#root")

export class JobExecutionLogList extends React.Component<JobExecutionLogLst, JobConfigListState> {
  state: JobExecutionLogLst = {
    items: [],
    totalPages: 0,
    PageNumbers: [],
    searchTerm: "",
    pageSize: 50,
    showModal: false,
    modalMessage: ""
  }

  async _ShowJobDetails(logId: string): Promise<void> {
    let Response = await JobExecutionLogService.GetExecutionLogDetails(logId)
    let Message = Response.displayMesage
    if (Response.detailedMessage !== null && Response.detailedMessage !== "") {
      Message += "\n" + Response.detailedMessage
    }
    this.openModal(Message)
  }

  async _setup(): Promise<void> {
    this.LoadDataForPage(1)
  }
  _renderJobLog(eLog: IJobExecutionLog): React.ReactNode {
    return (
      <TableRow key={eLog.id}>
        <TableCell>{eLog.jobConfigId}</TableCell>
        <TableCell>{eLog.jobId}</TableCell>
        <TableCell>{eLog.startDateTime}</TableCell>
        <TableCell>{eLog.endDateTime}</TableCell>
        <TableCell>{JobStatusNames[eLog.jobStatus]}</TableCell>
        <TableCell>{eLog.integrationType}</TableCell>
        <TableCell>{eLog.vendorType}</TableCell>
        <TableCell>
          <div onClick={() => this._ShowJobDetails(eLog.id)}>
            <IconButton>
              <Edit />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  async LoadDataForPage(pageNumber: number): Promise<void> {
    let Pages: number[] = []
    let configId = this.state.searchTerm
    let PageSize = this.state.pageSize
    let Response = await JobExecutionLogService.GetJobExecutionLogs(pageNumber, PageSize, configId)

    if (Response.pages > 0) {
      for (let i = 1; i <= Response.pages; i++) {
        Pages.push(i)
      }
    }

    this.setState({ items: Response.list, totalPages: Response.pages, PageNumbers: Pages })
  }

  _renderPageButton(page: number): React.ReactNode {
    return (
      <button key={page} className="PageButton" onClick={() => this.LoadDataForPage(page)}>
        {page}
      </button>
    )
  }

  _renderPageList(): React.ReactNode {
    return <div>{this.state.PageNumbers.map((pageNumber) => this._renderPageButton(pageNumber))}</div>
  }

  render(): React.ReactNode {
    return (
      <div>
        <div style={{ padding: "10px" }}>
          <input
            type="text"
            onChange={(event) => this.setState({ searchTerm: event.target.value })}
            placeholder="Search By Job Config Id"
            style={{ padding: "5px", marginRight: "5px" }}
          />
          <button id="btnSearch" onClick={() => this._setup()}>
            Search
          </button>
          <select
            id="PageSize"
            onChange={(event) => this.setState({ pageSize: event.target.value })}
            style={{ float: "right", clear: "both", padding: "5px" }}
          >
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={0}>All</option>
          </select>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Config Id</TableCell>
                <TableCell>Job Id</TableCell>
                <TableCell>Start date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Job Status</TableCell>
                <TableCell>Integration Type</TableCell>
                <TableCell>Vendor Type</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.items
                ? this.state.items.map((jobConfig) => this._renderJobLog(jobConfig))
                : this._renderJobLog(new JobExecutionLog(""))}
            </TableBody>
          </Table>
        </TableContainer>
        {this._renderPageList()}
        {this.renderModal()}
      </div>
    )
  }

  openModal(message: string): void {
    this.setIsOpen(true)
    this.setState({ modalMessage: message })
  }

  closeModal(): void {
    this.setIsOpen(false)
  }

  setIsOpen(open: boolean): void {
    this.setState({ showModal: open })
  }

  renderModal(): React.ReactNode {
    return (
      <Modal isOpen={this.state.showModal} onRequestClose={this.closeModal}>
        <h3>Details</h3>
        <p>{this.state.modalMessage}</p>

        <button onClick={() => this.closeModal()}>Close</button>
      </Modal>
    )
  }

  componentDidMount(): void {
    this._setup()
  }
}
