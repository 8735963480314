import { REACT_APP_API_BASE } from "services/Api/ApiBaseRouteConstants"
import { ApiWrapper } from "./AxiosWrapper"
import { IPaycorPerformTokenMapping } from "models/IPaycorPerformCredentialMapping"

class PaycorPerformTokenAPI {
  public PAYCOR_PERFORM_TOKEN_URL: string = "PaycorPerformToken/"

  public async GetPaycorPerformTokens(): Promise<IPaycorPerformTokenMapping[]> {
    return ApiWrapper.get(`${REACT_APP_API_BASE}${this.PAYCOR_PERFORM_TOKEN_URL}`, {})
  }

  public async UpdatePaycorPerformToken(paycorPerformToken: IPaycorPerformTokenMapping): Promise<IPaycorPerformTokenMapping> {
    paycorPerformToken.modifiedAt = new Date(Date.now())
    let updatedToken: IPaycorPerformTokenMapping = await ApiWrapper.put(
      `${REACT_APP_API_BASE}${this.PAYCOR_PERFORM_TOKEN_URL}${paycorPerformToken.id}`,
      paycorPerformToken,
      {}
    )
    if (paycorPerformToken && updatedToken?.modifiedAt !== null) {
      updatedToken.modifiedAt = new Date(updatedToken.modifiedAt)
    }
    return updatedToken
  }

  public async DeletePaycorPerformToken(id: string): Promise<IPaycorPerformTokenMapping> {
    return ApiWrapper.delete(`${REACT_APP_API_BASE}${this.PAYCOR_PERFORM_TOKEN_URL}${id}`, {})
  }
}

export const PaycorPerformTokenService: PaycorPerformTokenAPI = new PaycorPerformTokenAPI()
