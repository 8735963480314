import { ApiWrapper } from "./AxiosWrapper"
import { ONSHIFT_TIME_API_BASE } from "../components/Auth/PaycorAuthConstants"

class OnTimeApi {
  public Login(requestUsername: string, requestPassword: string): Promise<any> {
    let onTimeAxios = ApiWrapper
    onTimeAxios.removeAuthorizationHeader()
    return onTimeAxios.post(
      ONSHIFT_TIME_API_BASE + "/v1/users/login", 
      {
        email: requestUsername,
        password: requestPassword
      },
      {}
    )
  }
}

    
export const OnTimeApiService: OnTimeApi = new OnTimeApi()