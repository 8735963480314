import React, { FC, useEffect, useState } from "react"
import {
  Box,
  TextField,
  Button,
  makeStyles,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  FormHelperText
} from "@material-ui/core"
import "react-datepicker/dist/react-datepicker.css"
import { styles } from "../../css/shared-css"
import { Delete, Save } from "@material-ui/icons"
import { IPaycorPerformTokenMapping } from "models/IPaycorPerformCredentialMapping"
import { PaycorPerformTokenService } from "services/PaycorPerformTokenService"
import { MappingRowStyles } from "css/mapping-rows-css"

require("react-datepicker/dist/react-datepicker.css")

const useStyles = makeStyles(MappingRowStyles)

export const PaycorPerformCredentialMappings = () => {
  const [mappings, setMappings] = useState<IPaycorPerformTokenMapping[]>([])
  const [modifiedRow, setModifiedRow] = useState<boolean[]>([])
  const [initialLoaded, setInitialLoaded] = useState(false)
  const [errorNameRow, setErrorNameRow] = useState<boolean[]>([])

  const fetchPaycorPerformTokens = async () => {
    const paycorPerformTokenMappings = await PaycorPerformTokenService.GetPaycorPerformTokens()
    let modifiedRowsInit: boolean[] = []
    paycorPerformTokenMappings.forEach(() => {
      modifiedRowsInit.push(false)
    })

    setModifiedRow(modifiedRowsInit)
    setErrorNameRow(modifiedRowsInit)
    setMappings(paycorPerformTokenMappings)
    setInitialLoaded(true)
  }

  useEffect(() => {
    if (!initialLoaded) {
      fetchPaycorPerformTokens()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateMapping = (key: any, mapping: IPaycorPerformTokenMapping) => {
    let updatedMappings = [...mappings]
    updatedMappings[key] = mapping
    setMappings(updatedMappings)
    let modifiedRows = [...modifiedRow]
    modifiedRows[key] = true
    setModifiedRow(modifiedRows)
  }

  const deleteMapping = async (key: any, deletedMapping: IPaycorPerformTokenMapping) => {
    if (deletedMapping.id) {
      const result = await PaycorPerformTokenService.DeletePaycorPerformToken(deletedMapping.id)
      if (!result) {
        return
      }
    }
    const updatedMappings = mappings.filter((m) => m !== deletedMapping)
    setMappings(updatedMappings)

    let modifiedRows = [...modifiedRow]
    modifiedRows.splice(key, 1)
    setModifiedRow(modifiedRows)
  }

  const saveMapping = async (key: any, mapping: IPaycorPerformTokenMapping) => {
    if (isValidMapping(key, mapping)) {
      PaycorPerformTokenService.UpdatePaycorPerformToken(mapping)
      let modifiedRows = [...modifiedRow]
      modifiedRows[key] = false
      setModifiedRow(modifiedRows)
    }
  }

  const isValidMapping = (key: any, mapping: IPaycorPerformTokenMapping): boolean => {
    let isErrorName = mapping.name === ""
    let updatedErrorName = [...errorNameRow]
    updatedErrorName[key] = isErrorName
    setErrorNameRow(updatedErrorName)

    return !isErrorName
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Mapping ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">State</TableCell>
              <TableCell align="center">Chain or Company</TableCell>
              <TableCell align="center">Legal Entity ID</TableCell>
              <TableCell align="center">Last Modified</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {initialLoaded &&
              mappings.map((mapping, key) => (
                <PaycorPerformTokenMappingRow
                  key={key}
                  mapping={mapping}
                  index={key}
                  modified={modifiedRow[key]}
                  errorName={errorNameRow[key]}
                  onChange={updateMapping}
                  onDelete={deleteMapping}
                  onSave={saveMapping}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

interface PaycorPerformTokenMappingRowProps {
  mapping: IPaycorPerformTokenMapping
  index: number
  modified: boolean
  errorName: boolean
  onChange: (key: any, mapping: IPaycorPerformTokenMapping) => void
  onDelete: (key: any, deletedMapping: IPaycorPerformTokenMapping) => void
  onSave: (key: any, mapping: IPaycorPerformTokenMapping) => void
}

const PaycorPerformTokenMappingRow: FC<PaycorPerformTokenMappingRowProps> = ({
  mapping,
  index,
  modified,
  errorName,
  onChange,
  onDelete,
  onSave
}) => {
  const classes = useStyles()
  return (
    <TableRow>
      <React.Fragment key={index}>
        <TableCell
          id="parent"
          align="center"
          className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}
        >
          <Box style={styles.box} id="mappingId">
            <td style={{ textAlign: "center" }}>{mapping.id}</td>
          </Box>
        </TableCell>
        <TableCell align="center" className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="mappingName">
            <TextField
              value={mapping.name}
              onChange={(e) => {
                let updatedMapping = { ...mapping, name: e.target.value }
                onChange(index, updatedMapping)
              }}
              variant="outlined"
            />
            {errorName ? (
              <FormHelperText className={classes.centerText} error={errorName}>
                Required Field
              </FormHelperText>
            ) : null}
          </Box>
        </TableCell>
        <TableCell align="center" className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box id="mappingState">
            <TextField
              multiline={true}
              value={mapping.state}
              onChange={(e) => {
                let updatedMapping = { ...mapping, state: e.target.value }
                onChange(index, updatedMapping)
              }}
              variant="outlined"
            />
          </Box>
        </TableCell>
        <TableCell align="center" className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box id="mappingChainOrCompany">
            <TextField
              multiline={true}
              value={mapping.custOrChainOrCompany}
              onChange={(e) => {
                let updatedMapping = { ...mapping, custOrChainOrCompany: e.target.value }
                onChange(index, updatedMapping)
              }}
              variant="outlined"
            />
          </Box>
        </TableCell>
        <TableCell align="center" className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box id="mappingLegalEntityId">
            <TextField
              multiline={true}
              value={mapping.clientOrLegalID}
              onChange={(e) => {
                let updatedMapping = { ...mapping, clientOrLegalID: e.target.value }
                onChange(index, updatedMapping)
              }}
              variant="outlined"
            />
          </Box>
        </TableCell>
        <TableCell align="center" className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="mappingModifiedDate">
            <td style={{ textAlign: "center" }}>{new Date(mapping.modifiedAt).toLocaleString()}</td>
          </Box>
        </TableCell>
        <TableCell align="center">
          <TableCell style={{ borderBottom: "none" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={(_e) => onSave(index, mapping)}
              className={classes.saveButton}
            >
              <Save />
            </Button>
          </TableCell>
          <TableCell style={{ borderBottom: "none" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={(_e) => onDelete(index, mapping)}
              className={classes.saveButton}
            >
              <Delete />
            </Button>
          </TableCell>
        </TableCell>
      </React.Fragment>
    </TableRow>
  )
}
