import React from "react"

function DestinationCheckbox(props: any) {
  const defaultChecked = props.defaultChecked
  const toggleDestination = props.toggleDestination
  const destination = props.destination
  const checkboxText = props.checkboxText

  const handleChange = () => {
    toggleDestination(destination)
  }

  return (
    <label>
      <input type="checkbox" checked={defaultChecked} onChange={handleChange} />
      {checkboxText}
    </label>
  )
}

export { DestinationCheckbox }
