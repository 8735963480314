import { Box, CircularProgress, FormControl, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { styles } from "css/shared-css"
import { IChain } from "models/IChain"
import React, { FC, useEffect, useState } from "react"
import { getChains } from "services/ScheduleDataApiService"

export interface ChainSelectorProps {
  chain: IChain | null
  setChain: (chain: IChain | null) => void
}

export const ChainSelector: FC<ChainSelectorProps> = ({ chain, setChain }) => {
  const [chains, setChains] = useState<IChain[]>([])
  const [isLoadingChain, setIsLoadingChain] = useState(false)

  const fetchChains = async () => {
    setIsLoadingChain(true)
    let loadedChains = await getChains().finally(() => {
      setIsLoadingChain(false)
    })
    setChains(loadedChains ?? [])
    if (loadedChains.length === 1) {
      setChain(loadedChains[0])
    }
  }

  useEffect(() => {
    fetchChains()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box style={styles.box}>
      <FormControl style={styles.fullLength}>
        <Autocomplete
          id="chain"
          value={chain}
          loading={isLoadingChain}
          options={chains}
          getOptionLabel={(option) => option.chainName}
          disabled={chains.length <= 1}
          onChange={(_e, value) => setChain(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              style={styles.fullLength}
              label="Chain"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    {isLoadingChain ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.startAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  )
}
