import React, { FC, useEffect, useState } from "react"
import { Box, TextField, Button, Typography, Grid, makeStyles, FormHelperText } from "@material-ui/core"
import "react-datepicker/dist/react-datepicker.css"
import { styles } from "../../css/shared-css"
import { MappingRowStyles } from "css/mapping-rows-css"
import { Add, Delete, Save } from "@material-ui/icons"
import { IChain } from "models/IChain"
import { PaycorPerformDepartmentMapping } from "models/PaycorPerformDepartmentMapping"
import { ChainSelector } from "components/ChainSelector"
import { PaycorPerformDepartmentMappingApiService } from "services/PaycorPerformDepartmentMappingApi"
import { AxiosError } from "axios"
import WithErrorHandling from "components/error-banner"

const useStyles = makeStyles(MappingRowStyles)

export const PaycorPerformDepartmentMappings = () => {
  const [chain, setChain] = useState<IChain | null>(null)
  const [mappings, setMappings] = useState<PaycorPerformDepartmentMapping[]>([])
  const [modifiedRow, setModifiedRow] = useState<boolean[]>([])
  const [errorOrgRow, setErrorOrgRow] = useState<boolean[]>([])
  const [errorPositionRow, setErrorPositionRow] = useState<boolean[]>([])
  const [errorVendorKeyRow, setErrorVendorKeyRow] = useState<boolean[]>([])
  const [showError, setShowError] = useState<boolean>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const fetchDepartmentMappings = async (chainId: number) => {
    setMappings([])
    const departmentMappings =
      await PaycorPerformDepartmentMappingApiService.GetPaycorPerformDepartmentMappingsByChainId(chainId)
    let modifiedRowsInit: boolean[] = []
    departmentMappings.forEach(() => {
      modifiedRowsInit.push(false)
    })

    setModifiedRow(modifiedRowsInit)
    setErrorOrgRow(modifiedRowsInit)
    setErrorPositionRow(modifiedRowsInit)
    setErrorVendorKeyRow(modifiedRowsInit)
    setMappings(departmentMappings)
  }

  useEffect(() => {
    if (chain !== null) {
      fetchDepartmentMappings(chain.chainId)
    }
  }, [chain])

  const addNewMapping = () => {
    if (chain === null) return
    const newMapping: PaycorPerformDepartmentMapping = {
      id: undefined,
      chainId: chain.chainId,
      orgAbbreviation: "",
      position: "",
      location: "",
      vendorKey: ""
    }

    setMappings([...mappings, newMapping])
    setModifiedRow([...modifiedRow, true])
  }

  const updateMapping = (key: any, mapping: PaycorPerformDepartmentMapping) => {
    let updatedMappings = [...mappings]
    updatedMappings[key] = mapping
    setMappings(updatedMappings)
    let modifiedRows = [...modifiedRow]
    modifiedRows[key] = true
    setModifiedRow(modifiedRows)
  }

  const deleteMapping = async (key: any, deletedMapping: PaycorPerformDepartmentMapping) => {
    if (deletedMapping.id) {
      const result = await PaycorPerformDepartmentMappingApiService.DeletePaycorPerformDepartmentMapping(
        deletedMapping.id
      )
      if (!result) {
        return
      }
    }
    let updatedMappings = [...mappings]
    updatedMappings = updatedMappings.filter((m) => m !== deletedMapping)
    setMappings(updatedMappings)

    let modifiedRows = [...modifiedRow]
    modifiedRows.splice(key, 1)
    setModifiedRow(modifiedRows)
  }

  const SaveMapping = async (key: any, mapping: PaycorPerformDepartmentMapping) => {
    let updatedMappings = [...mappings]
    if (isValidMapping(key, mapping)) {
      try {
        if (mapping.id) {
          await PaycorPerformDepartmentMappingApiService.UpdatePaycorPerformDepartmentMapping(mapping)
        } else {
          const result = await PaycorPerformDepartmentMappingApiService.PostPaycorPerformDepartmentMapping(mapping)
          updatedMappings[key].id = result.id
          setMappings(updatedMappings)
        }
        let modifiedRows = [...modifiedRow]
        modifiedRows[key] = false
        setModifiedRow(modifiedRows)
      } catch (ex) {
        let error = ex as AxiosError
        setShowError(true)
        setErrorMessage("Error while inserting: " + error.response?.data.responseData)
      }
    }
  }

  const isValidMapping = (key: any, mapping: PaycorPerformDepartmentMapping): boolean => {
    let isErrorOrg = mapping.orgAbbreviation === ""
    let updatedErrorOrg = [...errorOrgRow]
    updatedErrorOrg[key] = isErrorOrg
    setErrorOrgRow(updatedErrorOrg)

    let isErrorPosition = mapping.position === ""
    let updatedErrorPosition = [...errorPositionRow]
    updatedErrorPosition[key] = isErrorPosition
    setErrorPositionRow(updatedErrorPosition)

    let isErrorVendorKey = mapping.vendorKey === ""
    let updatedErrorVendorKey = [...errorVendorKeyRow]
    updatedErrorVendorKey[key] = isErrorVendorKey
    setErrorVendorKeyRow(updatedErrorVendorKey)

    return !isErrorOrg && !isErrorPosition && !isErrorVendorKey
  }

  const setOpen = (value: boolean): void => {
    setShowError(value)
  }

  return (
    <div>
      <ChainSelector chain={chain} setChain={setChain} />
      {chain === null && (
        <Typography variant="h6" align="center">
          Please Select a Chain
        </Typography>
      )}
      <WithErrorHandling open={showError} setOpen={setOpen} errorMessage={String(errorMessage)} />
      <Grid container>
        {mappings.map((mapping, key) => (
          <PaycorPerformDepartmentMappingRow
            key={key}
            mapping={mapping}
            index={key}
            modified={modifiedRow[key]}
            errorOrg={errorOrgRow[key]}
            errorPosition={errorPositionRow[key]}
            errorVendorKey={errorVendorKeyRow[key]}
            onChange={updateMapping}
            onDelete={deleteMapping}
            onSave={SaveMapping}
          />
        ))}
        <Grid item xs={12}>
          <Box style={styles.box}>
            <Button
              variant="contained"
              color="primary"
              onClick={addNewMapping}
              id="addMapping"
              disabled={chain === null}
            >
              <Add />
              Add Mapping
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

interface PaycorPerformDepartmentMappingProps {
  mapping: PaycorPerformDepartmentMapping
  index: number
  modified: boolean
  errorOrg: boolean
  errorPosition: boolean
  errorVendorKey: boolean
  onChange: (key: any, mapping: PaycorPerformDepartmentMapping) => void
  onDelete: (key: any, deletedMapping: PaycorPerformDepartmentMapping) => void
  onSave: (key: any, mapping: PaycorPerformDepartmentMapping) => void
}

const PaycorPerformDepartmentMappingRow: FC<PaycorPerformDepartmentMappingProps> = ({
  mapping,
  index,
  modified,
  errorOrg,
  errorPosition,
  errorVendorKey,
  onChange,
  onDelete,
  onSave
}) => {
  const classes = useStyles()

  return (
    <React.Fragment key={index}>
      <Grid container spacing={2}>
        <Grid
          container
          item
          xs={2}
          id="parent"
          className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}
        >
          <Box style={styles.box} id="orgAbbreviation">
            <TextField
              defaultValue={mapping.orgAbbreviation}
              onChange={(e) => {
                let updatedMapping = { ...mapping, orgAbbreviation: e.target.value }
                onChange(index, updatedMapping)
              }}
              label="Org Abbreviation"
              variant="outlined"
            />
            {errorOrg ? (
              <FormHelperText className={classes.centerText} error={errorOrg}>
                Required Field
              </FormHelperText>
            ) : null}
          </Box>
        </Grid>
        <Grid container item xs={2} className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="Position">
            <TextField
              defaultValue={mapping.position}
              onChange={(e) => {
                let updatedMapping = { ...mapping, position: e.target.value }
                onChange(index, updatedMapping)
              }}
              label="Position"
              variant="outlined"
            />
            {errorPosition ? (
              <FormHelperText className={classes.centerText} error={errorPosition}>
                Required Field
              </FormHelperText>
            ) : null}
          </Box>
        </Grid>
        <Grid container item xs={2} className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="Location">
            <TextField
              defaultValue={mapping.location}
              onChange={(e) => {
                let updatedMapping = { ...mapping, location: e.target.value }
                onChange(index, updatedMapping)
              }}
              label="Location"
              variant="outlined"
            />
          </Box>
        </Grid>
        <Grid container item xs={2} className={mapping.id && !modified ? classes.existingIdRow : classes.newIdRow}>
          <Box style={styles.box} id="Vendor Key">
            <TextField
              defaultValue={mapping.vendorKey}
              onChange={(e) => {
                let updatedMapping = { ...mapping, vendorKey: e.target.value }
                onChange(index, updatedMapping)
              }}
              label="Vendor Key"
              variant="outlined"
              style={{ display: "flex" }}
            />
            {errorVendorKey ? (
              <FormHelperText className={classes.centerText} error={errorVendorKey}>
                Required Field
              </FormHelperText>
            ) : null}
          </Box>
        </Grid>
        <Grid container item xs={2}>
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Box style={styles.box}>
              <Button
                variant="contained"
                color="primary"
                onClick={(_e) => onSave(index, mapping)}
                className={classes.saveButton}
              >
                <Save />
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(_e) => onDelete(index, mapping)}
                className={classes.saveButton}
              >
                <Delete />
              </Button>
            </Box>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
