import React from "react"
import { Box, Button, Typography } from "@material-ui/core"
import { Link, useHistory } from "react-router-dom"
import { styles } from "css/shared-css"
import { MapTypeEnum } from "models/MCP/DenaliMaps"

export const IntegrationMaps = () => {
  const history = useHistory()
  const linkHandler = (path: string, type: MapTypeEnum) => {
    const location = { pathname: path, state: type }
    history.replace(location)
  }
  return (
    <>
      <Box style={styles.box}>
        <Typography variant="h6" align="left">
          Integrations Mappings
        </Typography>
        <Box style={styles.box}>
          <Typography variant="body1" align="left">
            Incoming Jobs
          </Typography>
          <Box margin="1em">
            <Button
              onClick={() => linkHandler("/employeeJobCodeMappings", MapTypeEnum.EmpJobCode)}
              variant="contained"
              color="primary"
            >
              Employee Job Codes
            </Button>
          </Box>
          <Box margin="1em">
            <Button
              onClick={() => linkHandler("/punchJobCodeMappings", MapTypeEnum.PunchJobCode)}
              variant="contained"
              color="primary"
            >
              Punch Job Codes
            </Button>
          </Box>
          <Box margin="1em">
            <Button
              onClick={() => linkHandler("/punchPayCodeMappings", MapTypeEnum.PunchPayCode)}
              variant="contained"
              color="primary"
            >
              Punch Pay Codes
            </Button>
          </Box>
          <Box margin="1em">
            <Button
              onClick={() => linkHandler("/flsaCodeMappings", MapTypeEnum.FlsaCode)}
              variant="contained"
              color="primary"
            >
              FLSA Codes
            </Button>
          </Box>
          <Box margin="1em">
            <Button
              onClick={() => linkHandler("/facilityMappings", MapTypeEnum.FacilityMapping)}
              variant="contained"
              color="primary"
            >
              Facilities
            </Button>
          </Box>
        </Box>
        <Box style={styles.box}>
          <Typography variant="body1" align="left">
            Outgoing Jobs
          </Typography>
          <Box margin="1em">
            <Link to={`/PaycorPerformDepartmentMappings`}>
              <Button variant="contained" color="primary">
                Paycor Perform Schedule Out Department Mappings
              </Button>
            </Link>
          </Box>
        </Box>
        <Box style={styles.box}>
          <Typography variant="body1" align="left">
            Client Credentials
          </Typography>
          <Box margin="1em">
            <Link to={`/ADPSubscriptionMapping`}>
              <Button variant="contained" color="primary">
                ADP Subscription Mappings
              </Button>
            </Link>
          </Box>
          <Box margin="1em">
            <Link to={`/PaycorPerformCredentialMapping`}>
              <Button variant="contained" color="primary">
                Paycor Perform Credential Mappings
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  )
}
