import React from "react"
import {
  Box,
  TextField,
  FormControl,
  Paper,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CircularProgress
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import "react-datepicker/dist/react-datepicker.css"
import WithErrorHandling from "../components/error-banner"
import { JobConfigApiService } from "services/JobConfigApiService"
import { styles } from "../css/shared-css"
import { McpApiService } from "services/McpApiService"
import { ICompany } from "models/MCP/ICompany"
import { SimplePopover } from "components/Popover"
import { AxiosError } from "axios"

require("react-datepicker/dist/react-datepicker.css")

export type FileUploadProps = {}
export type FileUploadState = {
  selectedFile: any
  integrationTypes: string[]
  selectedIntegrationType: string
  isLoadingCompany: boolean
  isSubmitting: boolean
  company: ICompany | null
  errorCompany: boolean
  showError: boolean
  errorMessage: string
  fileName: string
  userCompanies: ICompany[]
  readyToSubmit: boolean
}

export class FileUpload extends React.Component<FileUploadProps, FileUploadState> {
  state: FileUploadState = {
    selectedFile: null,
    integrationTypes: ["EMPLOYEE", "PUNCH"],
    selectedIntegrationType: "",
    isLoadingCompany: true,
    isSubmitting: false,
    company: null,
    errorCompany: false,
    errorMessage: "",
    showError: false,
    fileName: "No file selected",
    userCompanies: [],
    readyToSubmit: false
  }

  async _setup(): Promise<void> {
    try {
      await this.loadCompanies()
      this.setState({ fileName: "No file selected" })
    } catch (ex) {}
  }

  async loadCompanies(): Promise<void> {
    var userAvailableCompanies = await McpApiService.GetUserCompanies().finally(() => {
      this.state.isLoadingCompany = false
    })
    this.setState({ userCompanies: userAvailableCompanies })
    if (userAvailableCompanies.length === 1) {
      this.setState({ company: userAvailableCompanies[0] })
    }
  }

  setOpen(value: boolean): void {
    this.setState({ showError: value })
  }

  onIntegrationTypeChange = (event: any) => {
    this.setState(
      {
        selectedIntegrationType: event.target.value
      },
      () => this.setReadyToSubmit()
    )
  }

  onFileChange = (event: any) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
        fileName: event.target.files[0].name
      },
      () => this.setReadyToSubmit()
    )
  }

  onCompanyChange = (event: any, selectedCompany: ICompany | null) => {
    if (selectedCompany != null) {
      this.setState(
        {
          company: selectedCompany,
          errorCompany: false
        },
        () => this.setReadyToSubmit()
      )
    }
  }

  setReadyToSubmit(): void {
    this.setState({
      readyToSubmit:
        this.state.fileName !== "No file selected" &&
        this.state.company != null &&
        this.state.selectedIntegrationType !== ""
    })
  }

  componentDidMount(): void {
    this._setup()
  }

  componentWillUnmount(): void {}

  async submit(event: any): Promise<void> {
    this.setState({
      isSubmitting: true
    })
    try {
      let companyId = this.state.company?.id.toString()
      if (companyId != null) {
        const formData = new FormData()
        formData.append(companyId, this.state.selectedFile, this.state.selectedFile.name)
        try {
          if (this.state.selectedIntegrationType === "EMPLOYEE") {
            await JobConfigApiService.PostEmployeeFile(formData, companyId)
          } else if (this.state.selectedIntegrationType === "PUNCH") {
            await JobConfigApiService.PostPunchFile(formData, companyId)
          }
        } catch (ex) {
          let error = ex as AxiosError
          if (error.response?.status === 422) {
            this.setState({
              showError: true,
              errorMessage: "Error while inserting: " + error.response?.data.responseData
            })
          } else {
            this.setState({ showError: true, errorMessage: "Error while inserting: " + ex })
          }
        }

        if (!this.state.showError) {
          this.setState({ readyToSubmit: false })
          alert(`File ${this.state.selectedFile.name} has been submitted to ${this.state.company?.name}`)
        }

        this.setState({
          isSubmitting: false
        })
      }
    } catch (ex) {}
  }

  render(): React.ReactNode {
    return (
      <Paper>
        <WithErrorHandling
          open={this.state.showError}
          setOpen={this.setOpen.bind(this)}
          errorMessage={this.state.errorMessage}
        />
        <Box style={styles.box}>
          <Typography variant="h6" align="left">
            Data File Upload
          </Typography>
          <Box></Box>
          <Typography variant="body2" align="left">
            A header row is required to process files.
          </Typography>
        </Box>
        <Box style={styles.configOptionRow}>
          <Box style={styles.configOptionRowElement}>
            <SimplePopover
              buttonText={"Employee Headers"}
              popupText={
                "EmployeeID,FirstName,LastName,Title,HireDate,TerminationDate,FacilityID,JobCode,Email,MobileNumber,HomeNumber,sendMethod,Wage,EmpStatus,EmpOTRule,EmpCapacity,EmpPTOHours,EmpExpiration,EmpPayType"
              }
            />
          </Box>
          <Box style={styles.configOptionRowElement}>
            <SimplePopover
              buttonText={"Punch Headers"}
              popupText={"FacilityID,EmployeeID,ApplyDate,PunchIn,PunchOut,TotalHours,JobCode,PayCode"}
            />
          </Box>
        </Box>
        <Box style={styles.box}>
          <FormControl style={styles.formControl}>
            <InputLabel id="integration-type-label">Integration Type</InputLabel>
            <Select
              labelId="integration-type-label"
              id="integration-type"
              value={this.state.selectedIntegrationType}
              onChange={this.onIntegrationTypeChange}
            >
              {this.state.integrationTypes.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box style={styles.configOptionRow}>
          <Box style={styles.configOptionRowElement}>
            <Button variant="contained" component="label" color="primary">
              Upload File
              <input type="file" style={{ display: "none" }} onChange={this.onFileChange} />
            </Button>
          </Box>
          <Box style={styles.configOptionRowTextElement}>
            <InputLabel id="file-name-label">{this.state.fileName}</InputLabel>
          </Box>
        </Box>
        <Box style={styles.box}>
          <FormControl style={styles.fullLength} error={this.state.errorCompany}>
            <Autocomplete
              id="company"
              value={this.state.company}
              loading={this.state.isLoadingCompany}
              options={this.state.userCompanies}
              getOptionLabel={(option) => option.name}
              autoSelect={true}
              disabled={this.state.userCompanies == null || this.state.userCompanies.length <= 1}
              onChange={this.onCompanyChange}
              renderInput={(params) => (
                <TextField {...params} style={styles.fullLength} label="Company" variant="outlined" />
              )}
            />
            {this.state.errorCompany ? <FormHelperText>Error</FormHelperText> : null}
          </FormControl>
        </Box>
        <Box style={styles.box}>
          {this.state.isSubmitting ? (
            <Button variant="contained" color="primary">
              <CircularProgress color="inherit" />
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={!this.state.readyToSubmit}
              color="primary"
              onClick={(e) => this.submit(e)}
            >
              Submit
            </Button>
          )}
        </Box>
      </Paper>
    )
  }
}
