import { IJobConfig } from "models/IJobConfig"
import { IJobConfigSchedule } from "models/IJobConfigSchedule"
import { IJobConfigSubmittable } from "models/IJobConfigSubmittable"
import { IScheduleMessage } from "models/IScheduleMessage"
import { REACT_APP_API_BASE } from "services/Api/ApiBaseRouteConstants"
import {
  JOBCONFIGURI,
  JOBCONFIGSCHEDULEURI,
  INTEGRATIONTYPEURI,
  VENDORTYPEURI,
  EMPLOYEEUPLOAD,
  PUNCHUPLOAD,
  SERVICEBUSUNFORMATTED
} from "services/Api/JobConfigServiceConstants"
import { ApiWrapper } from "./AxiosWrapper"
import { JobConfig } from "models/JobConfig"
import { PagedJobConfig } from "models/PagedJobConfigs"
import { JobConfigSearchParam } from "models/JobConfigSearchParams"

class JobConfigApi {
  public GetJobConfigs(
    searchParams: JobConfigSearchParam
  ): Promise<PagedJobConfig> {
    return ApiWrapper.get(REACT_APP_API_BASE + JOBCONFIGURI, 
      searchParams
    )
  }

  public async GetJobConfig(id: string): Promise<IJobConfig> {
    let jobConfig: JobConfig = new JobConfig(await ApiWrapper.get(`${REACT_APP_API_BASE}${JOBCONFIGURI}${id}`, {}))
    // Make sure that savedAt is strictly a Date
    if (jobConfig && jobConfig?.savedAt !== null) {
      jobConfig.savedAt = new Date(jobConfig.savedAt)
    }
    return jobConfig
  }

  public async PostJobConfig(jobConfig: IJobConfigSubmittable): Promise<IJobConfig> {
    return  ApiWrapper.post(REACT_APP_API_BASE + JOBCONFIGURI, jobConfig, {})
  }

  public async PostJobConfigSchedule(id: string, scheduleMessage: IScheduleMessage): Promise<IJobConfigSchedule> {
    return  ApiWrapper.post(
      `${REACT_APP_API_BASE}${JOBCONFIGURI}${id}/${JOBCONFIGSCHEDULEURI}`,
      scheduleMessage,
      {}
    )
  }

  public async UpdateJobConfig(id: string, jobConfig: IJobConfig): Promise<IJobConfig> {
    jobConfig.savedAt = new Date(Date.now())
    let updatedJobConfig: IJobConfig = await ApiWrapper.put(`${REACT_APP_API_BASE}${JOBCONFIGURI}${id}`, jobConfig, {})
    if (jobConfig && updatedJobConfig?.savedAt !== null) {
      updatedJobConfig.savedAt = new Date(updatedJobConfig.savedAt)
    }
    return updatedJobConfig
  }

  public async UpdateJobConfigSchedule(id: string, scheduleMessage: IScheduleMessage): Promise<IJobConfigSchedule> {
    return  ApiWrapper.put(
      `${REACT_APP_API_BASE}${JOBCONFIGURI}${id}/${JOBCONFIGSCHEDULEURI}`,
      scheduleMessage,
      {}
    )
  }

  public async GetJobConfigSchedule(id: string): Promise<IJobConfigSchedule> {
    return  ApiWrapper.get(`${REACT_APP_API_BASE}${JOBCONFIGURI}${id}/${JOBCONFIGSCHEDULEURI}`, {})
  }

  public async DeleteJobConfig(id: string): Promise<IJobConfig> {
    return  ApiWrapper.delete(`${REACT_APP_API_BASE}${JOBCONFIGURI}${id}`, {})
  }

  public GetIntegrationTypes(): Promise<string[]> {
    return ApiWrapper.get(REACT_APP_API_BASE + INTEGRATIONTYPEURI, {})
  }

  public GetVendorTypes(): Promise<string[]> {
    return ApiWrapper.get(REACT_APP_API_BASE + VENDORTYPEURI, {})
  }

  public PostEmployeeFile(employeeFile: any, companyId: string): Promise<IJobConfigSchedule> {
    return ApiWrapper.post(`${REACT_APP_API_BASE}${EMPLOYEEUPLOAD}`, employeeFile, { CompanyId: companyId })
  }

  public PostPunchFile(punchFile: any, companyId: string): Promise<IJobConfigSchedule> {
    return ApiWrapper.post(`${REACT_APP_API_BASE}${PUNCHUPLOAD}`, punchFile, { CompanyId: companyId })
  }

  public PostUnformattedJobConfigToServiceBus(retrieverMessage: any): Promise<IJobConfigSchedule> {
    return ApiWrapper.post(`${REACT_APP_API_BASE}${SERVICEBUSUNFORMATTED}`, retrieverMessage, {})
  }
}

export const JobConfigApiService: JobConfigApi = new JobConfigApi()
