export const JOBCONFIGURI: string = 'jobConfig/'
export const JOBCONFIGSCHEDULEURI: string = 'schedule'
export const INTEGRATIONTYPEURI: string = 'integrationType/'
export const VENDORTYPEURI: string = 'vendorType/'
export const JOBCONFIGINDEX: string = '/'
export const EMPLOYEEUPLOAD: string = 'employee/upload/'
export const JOBEXECUTIONLOGAPIURI: string = 'JobExecutionLog/'
export const SERVICEBUS: string = 'ServiceBus/'
export const SERVICEBUSUNFORMATTED: string = 'ServiceBus/unformatted'

export const PUNCHUPLOAD: string = 'punch/upload/'

export enum JobConfigSortIndex {
    NONE = 1,
    SAVED_AT = 2

}

export enum JobConfigSortMethod {
    NONE = 1,
    DESCENDING = 2,
    ASCENDING = 3
}