import React, { FC, useEffect, useState } from "react"
import { Box, Button, Typography, Grid } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import "react-datepicker/dist/react-datepicker.css"
import { styles } from "../../css/shared-css"
import { McpApiService } from "services/McpApiService"
import { ICommunity } from "models/MCP/ICommunity"
import { CommunitySelector } from "../../components/CommunitySelector"
import { Department } from "models/MCP/Department"
import { Add } from "@material-ui/icons"
import { APIMapInterface, IncomingJobMapInterface, MapTypeEnum } from "models/MCP/DenaliMaps"
import { ICompany } from "models/MCP/ICompany"
import { IncomingJobMapRow } from "./IncomingJobMapRow"
import { McpApiUtils } from "utils/McpApiServiceUtils"
import { AxiosError } from "axios"
import WithErrorHandling from "components/error-banner"

/*

This component is meant to be used for incoming job mappings: EmployeeJobCode, 
PunchJobCode, FacilityId and FlsaCode. Any additional mappings added will have to be first 
added to the MapTypeEnum type. If the mapping being added requires filtering at a company 
level add it to the filteredByCompany() below and follow the model for the facility enumType
in the api services
-------------------------------------------------------------------------------------------
*/

export const IncomingJobMap: FC<any> = () => {
  const history = useHistory()
  const [company, setCompany] = useState<ICompany | null>(null)
  const [community, setCommunity] = useState<ICommunity | null>(null)
  const [communities, setCommunities] = useState<ICommunity[]>()
  const [communityFilteredOptions, setOptions] = useState<Department[]>([])
  const [mappings, setMappings] = useState<IncomingJobMapInterface[]>([])
  const [modifiedRow, setModifiedRow] = useState<boolean[]>([])
  const [showError, setShowError] = useState<boolean>()
  const [errorMessage, setErrorMessage] = useState<string>()

  // method checks to see whether the mapping type should be filtered by community or company
  const filteredByCompany = () => history.location.state === MapTypeEnum.FacilityMapping

  // will get corresponding maps by checking the type in second param of 'GetMaps()'
  const fetchMappings = async (id: string) => {
    if (!id) return
    let mappings = await McpApiService.GetMaps(id, history.location.state)
    let modifiedRowsInit: boolean[] = []
    if (mappings) {
      mappings.forEach(() => modifiedRowsInit.push(false))
    } else {
      mappings = []
    }
    setModifiedRow(modifiedRowsInit)
    setMappings(mappings)
  }

  // the following method will only run if we are filtering by community, otherwise 'fetchCommunities()'
  //  method will propagate the selector for the mapping row

  const fetchMappingSelectorData = async (id?: string) => {
    if (!id) return
    const departments = await McpApiService.GetDepartmentsByCommunity(id, history.location.state)
    setOptions(departments)
  }

  // the following method will run when a company has been selected and will either propagate the mapping
  //  selector if mappings grouped by company or the community selector if grouped by community
  const fetchCommunities = async (company: ICompany) => {
    let foundCommunities = await McpApiService.GetUserCommunitiesByCompany(company.id)
    setCommunities(foundCommunities)
  }

  useEffect(() => {
    if (!company || !filteredByCompany()) return
    fetchCommunities(company)
    fetchMappings(company.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  useEffect(() => {
    if (!community || filteredByCompany()) return
    fetchMappingSelectorData(community.id)
    fetchMappings(community.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community])

  // THE FOLLOWING METHODS ARE UNIVERSAL FOR MCP/DENALI MAPPINGS
  //--------------------------------------------------------------------
  const addNewMapping = () => {
    if (!communityFilteredOptions || (!communities && filteredByCompany())) return
    const newMapping: IncomingJobMapInterface = {
      id: undefined,
      customerCode: "",
      tableId: "",
      companyId: filteredByCompany() ? company?.id : "",
      communityId: !filteredByCompany() ? community?.id : ""
    }
    setMappings([...mappings, newMapping])
    setModifiedRow([...modifiedRow, true])
  }

  const updateMapping = (key: any, mapping: IncomingJobMapInterface) => {
    let updatedMappings = [...mappings]
    updatedMappings[key] = mapping
    setMappings(updatedMappings)
    let modifiedRows = [...modifiedRow]
    modifiedRows[key] = true
    setModifiedRow(modifiedRows)
  }

  const deleteMapping = async (key: any, mapping: IncomingJobMapInterface) => {
    const deletedMap = McpApiUtils.MapTypeSetter(history.location.state as MapTypeEnum, mapping)
    if (mapping.id) {
      const result = await McpApiService.DeleteMap(deletedMap)
      if (!result) {
        return
      }
    }
    const updatedMappings = mappings.filter((m) => m !== mapping)
    setMappings(updatedMappings)

    let modifiedRows = [...modifiedRow]
    modifiedRows.splice(key, 1)
    setModifiedRow(modifiedRows)
  }

  const saveMapping = async (key: any, mapping: IncomingJobMapInterface) => {
    let newMap: APIMapInterface
    const type = history.location.state as MapTypeEnum
    try {
      if (mapping.id) {
        newMap = McpApiUtils.MapTypeSetter(type, mapping)
        McpApiService.UpdateMap(newMap)
      } else {
        newMap = McpApiUtils.MapTypeSetter(type, mapping)
        const result = await McpApiService.PostMap(newMap)
        let updatedMappings = [...mappings]
        updatedMappings[key].id = result.id
        setMappings(updatedMappings)
      }
      let modifiedRows = [...modifiedRow]
      modifiedRows[key] = false
      setModifiedRow(modifiedRows)
    } catch (ex) {
        let error = ex as AxiosError
        setShowError(true)
        setErrorMessage("Error while inserting: " + error.response?.data.responseData)
      }
  }

  const setOpen = (value: boolean): void => {
    setShowError(value)
  }
  //--------------------------------------------------------------------

  return (
    <div>
      <CommunitySelector
        company={company}
        setCompany={setCompany}
        type={history.location.state}
        community={community}
        setCommunity={setCommunity}
      />
      {community === null && !filteredByCompany() && (
        <Typography variant="h6" align="center">
          Please Select a Community
        </Typography>
      )}
      {company === null && filteredByCompany() && (
        <Typography variant="h6" align="center">
          Please Select a Company
        </Typography>
      )}
      <WithErrorHandling open={showError} setOpen={setOpen} errorMessage={String(errorMessage)} />
      {((!filteredByCompany() && communityFilteredOptions?.length > 0) || (filteredByCompany() && communities)) && (
        <Grid container>
          {mappings.map((mapping, key) => (
            <IncomingJobMapRow
              type={history.location.state}
              mapping={mapping}
              index={key}
              communities={communities}
              communityFilteredOptions={communityFilteredOptions}
              filteredByCompany={filteredByCompany}
              modified={modifiedRow[key]}
              onChange={updateMapping}
              onDelete={deleteMapping}
              onSave={saveMapping}
            />
          ))}
          <Grid item xs={12}>
            <Box style={styles.box}>
              <Button variant="contained" color="primary" onClick={addNewMapping} id="addMapping">
                <Add />
                Add Mapping
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
