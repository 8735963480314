import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import CssBaseline from "@material-ui/core/CssBaseline"
import { App } from "pages/app"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { AUTH_AUDIENCE, AUTH_CLIENT_ID, AUTH_DOMAIN, AUTH_SCOPES } from "services/Api/Auth0Constants"
import { Auth0Provider } from "components/Auth/Auth0Provider"
import { history } from "util.js"

//this is necessary to allow us to inject environment variables to the window object at docker start
declare global {
  interface Window {
    REACT_APP_API_BASE?: string
    REACT_APP_AUTH_DOMAIN?: string
    REACT_APP_AUTH_CLIENT_ID?: string
    REACT_APP_AUTH_AUDIENCE?: string
    REACT_APP_AUTH_REDIRECT?: string
    REACT_APP_AUTH_SCOPES?: string
  }
}

const onRedirectCallback = (appState: any) => {
  history.push("/")
}

ReactDOM.render(
  <Auth0Provider
    domain={AUTH_DOMAIN}
    client_id={AUTH_CLIENT_ID}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={AUTH_AUDIENCE}
    scope={AUTH_SCOPES}
    useRefreshTokens={true}
    cacheLocation={"memory"}
  >
    <BrowserRouter>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  </Auth0Provider>,
  document.querySelector("#root")
)
