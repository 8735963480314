import {
  FlsaCodeEnum,
  MapTypeEnum,
  APIMapInterface,
  JobCodeInterface,
  FacilityInterface,
  FlsaCodeInterface,
  IncomingJobMapInterface,
  PayCodeInterface
} from "models/MCP/DenaliMaps"
import { Department } from "models/MCP/Department"
import {
  EMP_JOB_CODES_BY_COMMUNITY_ID,
  PUNCH_JOB_CODES_BY_COMMUNITY_ID,
  PUNCH_PAY_CODES_BY_COMMUNITY_ID,
  FLSA_CODES_BY_COMMUNITY_ID,
  FACILITY_MAPS_BY_COMPANY
} from "services/Api/McpServiceConstants"
class McpUtils {
  actions = [
    EMP_JOB_CODES_BY_COMMUNITY_ID,
    PUNCH_JOB_CODES_BY_COMMUNITY_ID,
    PUNCH_PAY_CODES_BY_COMMUNITY_ID,
    FLSA_CODES_BY_COMMUNITY_ID,
    FACILITY_MAPS_BY_COMPANY
  ]
  flsaCodes: Department[] = [
    {
      id: FlsaCodeEnum.Exempt.toString(),
      name: "Exempt",
      jobFunctions: []
    },
    { id: FlsaCodeEnum.NonExempt.toString(), name: "Non Exempt", jobFunctions: [] },
    { id: FlsaCodeEnum.Contractor.toString(), name: "Contractor", jobFunctions: [] }
  ]

  public MapTypeSetter(type: MapTypeEnum, mapping: IncomingJobMapInterface) {
    let apiMap: APIMapInterface = {
      action: () => "",
      pathname: "",
      map: undefined
    }
    switch (type) {
      case MapTypeEnum.EmpJobCode:
        apiMap = {
          id: mapping.id,
          action: FLSA_CODES_BY_COMMUNITY_ID,
          pathname: "EmployeeJobCode",
          map: {
            id: mapping.id,
            customerJobCode: mapping.customerCode,
            communityId: mapping.communityId,
            jobFunctionId: mapping.tableId
          } as JobCodeInterface
        } as APIMapInterface
        break
      case MapTypeEnum.PunchJobCode:
        apiMap = {
          id: mapping.id,
          action: FLSA_CODES_BY_COMMUNITY_ID,
          pathname: "PunchJobCode",
          map: {
            id: mapping.id,
            customerJobCode: mapping.customerCode,
            communityId: mapping.communityId,
            jobFunctionId: mapping.tableId
          } as JobCodeInterface
        } as APIMapInterface
        break
      case MapTypeEnum.PunchPayCode:
        apiMap = {
          id: mapping.id,
          action: PUNCH_PAY_CODES_BY_COMMUNITY_ID,
          pathname: "PunchPayCode",
          map: {
            id: mapping.id,
            customerPayCode: mapping.customerCode,
            communityId: mapping.communityId,
            payCodeId: mapping.tableId
          } as PayCodeInterface
        } as APIMapInterface
        break
      case MapTypeEnum.FacilityMapping:
        apiMap = {
          id: mapping.id,
          action: FACILITY_MAPS_BY_COMPANY,
          pathname: "FacilityIdMap",
          map: {
            id: mapping.id,
            facilityId: mapping.customerCode,
            companyId: mapping.companyId,
            communityId: mapping.tableId
          } as FacilityInterface
        } as APIMapInterface
        break
      case MapTypeEnum.FlsaCode:
        apiMap = {
          id: mapping.id,
          action: FLSA_CODES_BY_COMMUNITY_ID,
          pathname: "FlsaMapping",
          map: {
            id: mapping.id,
            customerFlsaCode: mapping.customerCode,
            communityId: mapping.communityId,
            flsaValue: parseInt(mapping.tableId) as MapTypeEnum
          } as FlsaCodeInterface
        } as APIMapInterface
        break
    }
    return apiMap
  }

  public TypeParser(type: MapTypeEnum, mapping: any) {
    if (type === MapTypeEnum.EmpJobCode || type === MapTypeEnum.PunchJobCode) {
      return {
        id: mapping.id,
        customerCode: mapping.customerJobCode,
        tableId: mapping.jobFunctionId,
        communityId: mapping.communityId
      }
    }
    if (type === MapTypeEnum.PunchPayCode) {
      return {
        id: mapping.id,
        customerCode: mapping.customerPayCode,
        tableId: mapping.payCodeId,
        communityId: mapping.communityId
      }
    }
    if (type === MapTypeEnum.FacilityMapping) {
      return {
        id: mapping.id,
        customerCode: mapping.facilityId,
        tableId: mapping.communityId,
        companyId: mapping.companyId
      }
    }
    if (type === MapTypeEnum.FlsaCode) {
      return {
        id: mapping.id,
        customerCode: mapping.customerFlsaCode,
        tableId: mapping.flsaValue,
        communityId: mapping.communityId
      }
    }
  }
}

export const McpApiUtils: McpUtils = new McpUtils()
